import React from "react";
import { useNavigate } from "react-router-dom";

const LoginBanner = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <div className="login_banner">
      <div className="container">
        <div className="logo_im" onClick={() => handleRedirect()}>
          <img style={{ height: "100%", width: "100px" }} src="/assets/Logo.png"/>
        </div>
        <br/>
        <div className="login_content" style={{marginTop : "-30px"}}>
          <h3>Welcome!</h3>
          <p>
            Login To Admin
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginBanner;
