import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons/Button";
import Label from "../../../../../components/Label";
import { Select, DatePicker } from "antd";
import { showNotification } from "../../../../../util/Utility";
import styled from "styled-components";
import { callApiV2 } from "../../../../../util/apiCaller";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon/Icon";
import dayjs from "dayjs";
import { UPDATE_APPROVAL_STATUS } from "../../../mosqueActionType";
import { memberApprove, memberDecline } from "../../../mosqueAction";

const paymentMethodOption = [
  {
    label: "Manual Pay",
    value: "manual",
  },
];

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }
  .ant-picker-focused {
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1a1a1a;
  }
`;

const AddForm = ({ details = null, memberSubId = null, approvalId = null }) => {
  const dispatch = useDispatch();

  const [isNext, setIsNext] = useState(false);
  const [isAddedNew, setIsAddedNew] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isDecline, setIsDecline] = useState(false);

  const [errors, setErrors] = useState({});

  const [user, setUserData] = useState({
    contact_name: "",
    contact_email: "",
    country_code: "+31",
    contact_phone: "",
    address: "",
    payment_method: "manual",
    subscription_plan_id: "",
    membership_date: new Date().toISOString(),
    membership: "",
  });

  useEffect(() => {
    if (details) {
      const data = {};
      data.contact_name = details?.customer?.contact_name;
      data.contact_email = details?.customer?.contact_email;
      data.country_code = details?.customer?.country_code;
      data.contact_phone = details?.customer?.contact_phone;
      data.address = details?.customer?.address;
      data.payment_method = details?.memberSubscriptions?.[0]?.payment_method;
      data.subscription_plan_id =
        details?.memberSubscriptions?.[0]?.subscription_plan_id;
      data.membership_date = details?.memberSubscriptions?.[0]?.member_since;
      data.membership = details?.memberSubscriptions?.[0]?.membership;
      setUserData(data);
    } else {
      setUserData({
        contact_name: "",
        contact_email: "",
        country_code: "+31",
        contact_phone: "",
        address: "",
        payment_method: "manual",
        subscription_plan_id: "",
        membership_date: new Date().toISOString(),
        membership: "",
      });
    }
  }, [details]);

  const isApprovalDecline = useSelector((state) => state.mosqueReducer.isApprovalDecline);
  const isApprovedApproval = useSelector((state) => state.mosqueReducer.isApprovedApproval);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const memberPlans = useSelector((state) => state.mosqueReducer.memberPlans);
  const plan_list = memberPlans?.planList;

  const formValidation = () => {
    return (
      !!user?.contact_name?.trim() &&
      !!user?.contact_email?.trim() &&
      !!user?.address?.trim() &&
      !!user?.payment_method?.trim() &&
      !!user?.subscription_plan_id &&
      !!user?.membership_date &&
      !!user?.membership?.trim()
    );
  };

  useEffect(() => {
    if (isApprovalDecline) {
      setTimeout(() => {
        window.location.href = `/mosque/${activeEntity?._id}/members/add`;
      }, 1000)
    }
  }, [isApprovalDecline]);

  useEffect(() => {
    if (user) {
      setIsNext(formValidation());
    }
  }, [user]);

  const handleChangeUser = (keyName, keyValue) => {
    let update = { ...user };
    if (keyName === "subscription_plan_id") {
      const selectedPlan = plan_list.filter(
        (item) => item._id === keyValue
      )?.[0];
      update["membership"] = selectedPlan?.plan_name;
      update[keyName] = keyValue;
    }
    if (keyName === "membership_date") {
      update["membership_date"] = new Date(keyValue?.$d).toISOString();
    }
    update[keyName] = keyValue;
    setUserData(update);
  };

  const optionsPlan = () => {
    let listValues = [];
    if (plan_list?.length) {
      const list = plan_list?.forEach((reply, index) => {
        listValues.push({
          ...reply,
          label: reply?.plan_name,
          value: reply?._id,
        });
      });
    }
    return listValues;
  };

  const SubmitMember = async () => {
    let update = { ...user };
    update.entity_id = activeEntity?._id;
    update.created_by_admin = true;

    const trimmedDetails = {};
    // Trim each attribute and assign it to trimmedDetails object
    for (const key in update) {
      if (typeof update[key] === "string") {
        trimmedDetails[key] = update[key].trim();
      } else {
        trimmedDetails[key] = update[key];
      }
    }

    const res = await callApiV2({
      method: "post",
      endpoint: `onboarding/new-member-signup`,
      body: {
        customer: trimmedDetails,
      },
      isErrorSuppressed: true,
    });
    if (res?.status === "Success") {
      setIsAddedNew(true);
      showNotification("success", "New member created successfully.");
      setUserData({
        contact_name: "",
        contact_email: "",
        country_code: "+31",
        contact_phone: "",
        address: "",
        payment_method: "manual",
        subscription_plan_id: "",
        membership_date: "",
        membership: "",
      });
    } else {
      showNotification(
        "error",
        res?.data?.message || "Something went wrong..."
      );
    }
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!user.contact_email || !isEmail(user.contact_email)) {
      isValid = false;
      error["contact_email"] = "Please enter valid email address";
    }
    return {
      isValid,
      error,
    };
  };

  const handleEmailValidation = () => {
    setErrors({});
    let validate = handleValidation();
    if (!validate.isValid) {
      setErrors(validate.error);
    }
  };

  const handleButtonClick = () => {
    setIsAddedNew(false);
    window.location.href = `/mosque/${activeEntity?._id}/members/add`;
  };

  const handleApproval = () => {
    dispatch({
      type: UPDATE_APPROVAL_STATUS,
      isApprovedApproval: false
    });

    window.location.href = `/mosque/${activeEntity?._id}/members/add`;
  }

  const handleClickDecline = () => {
    if (approvalId && activeEntity?._id) {
      dispatch(memberDecline(approvalId, activeEntity?._id));
    }
  }

  const handleClickApproval = () => {
    if (memberSubId && approvalId) {
      dispatch(memberApprove(memberSubId, approvalId));
    }
  }

  const getRenderButton = () => {
    if (details) {
      return (
        <Box display="flex" justifyContent="center">
          <Box>
            <Button
              type="outlineColor"
              text="Decline Member"
              borderRadius="66px"
              minWidth="200px"
              mt="4"
              mx="auto"
              height="50px"
              onClick={() => setIsDecline(true)}
            />
          </Box>
          <Box ml="15px">
            <Button
              type="primary"
              text="Approve Member"
              borderRadius="66px"
              minWidth="200px"
              mt="4"
              mx="auto"
              height="50px"
              onClick={() => handleClickApproval()}
            />
          </Box>
        </Box>
      );
    } else {
      return (
        <Button
          type={!isNext ? "disabled" : "primary"}
          text="Save Details"
          borderRadius="66px"
          width="15%"
          mt="4"
          mx="auto"
          height="60px"
          onClick={() => SubmitMember()}
        />
      );
    }
  };

  return (
    <div className="add_form form_width">
      <div className="mosque-form">
        <h2>Add Member</h2>
        <div className="row">
          <div className="col-lg-6 input-width">
            <Label mb="8px" isMandatory>
              Full Name
            </Label>
            <input
              value={user?.contact_name}
              onChange={(e) => handleChangeUser("contact_name", e.target.value)}
              type="text"
              placeholder="Enter name"
              className="input-box"
            />
            {errors.contact_name && (
              <ErrorMessage>{errors.contact_name}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 input-width">
            <Label mb="8px" isMandatory>
              Email Address
            </Label>
            <input
              onBlur={() => handleEmailValidation()}
              value={user?.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder="Enter Email Address"
              className="input-box"
            />
            {errors.contact_email && (
              <ErrorMessage>{errors.contact_email}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <Label mb="8px">
              Contact Number
            </Label>
            <Input
              value={user?.contact_phone}
              countryCodes={true}
              countryCodeValue={user?.country_code}
              setCountryCode={(e) => handleChangeUser("country_code", e)}
              onChange={(e) =>
                handleChangeUser("contact_phone", e.target.value)
              }
              border={user?.contact_phone?.length ? "1px solid #606734" : null}
              placeholder="785-785-4644"
              className="input-box"
              type="number"
              pattern="[0-9]"
              height="52px"
              overflow="unset"
            />
            {errors.contact_phone && (
              <ErrorMessage>{errors.contact_phone}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <Label mb="8px" isMandatory>
              Address
            </Label>
            <input
              value={user?.address}
              onChange={(e) => handleChangeUser("address", e.target.value)}
              type="text"
              placeholder="Enter Address"
              className="input-box"
              height="66px"
            />
            {errors.address && <ErrorMessage>{errors.address}</ErrorMessage>}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <Label mb="8px" isMandatory>
              Payment Method
            </Label>
            <Select
              size="middle"
              value={user?.payment_method || "Select method"}
              defaultValue="Select method"
              onChange={(value) => handleChangeUser("payment_method", value)}
              style={{ width: "100%" }}
              options={paymentMethodOption}
            />
            {errors.payment_method && (
              <ErrorMessage>{errors.payment_method}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <Label mb="8px" isMandatory>
              Plan
            </Label>
            <Select
              size="middle"
              value={user?.subscription_plan_id || "Select Plan"}
              defaultValue="Select Plan"
              onChange={(value) =>
                handleChangeUser("subscription_plan_id", value)
              }
              style={{ width: "100%" }}
              options={optionsPlan()}
            />
            {errors.subscription_plan_id && (
              <ErrorMessage>{errors.subscription_plan_id}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <Label mb="8px" isMandatory>
              Membership Date
            </Label>
            <DatePickerBox>
              <DatePicker
                defaultValue={dayjs()}
                value={dayjs(user?.membership_date, "YYYY-MM-DD")}
                onChange={(value) => handleChangeUser("membership_date", value)}
              />
            </DatePickerBox>
            {errors.membership_date && (
              <ErrorMessage>{errors.membership_date}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <Label mb="8px" isMandatory>
              Membership
            </Label>
            <input
              value={user.membership}
              onChange={(e) => handleChangeUser("membership", e.target.value)}
              type="text"
              placeholder="Enter Membership"
              className="input-box"
              height="66px"
            />
            {errors.membership && (
              <ErrorMessage>{errors.membership}</ErrorMessage>
            )}
          </div>
        </div>
        {getRenderButton()}
        <br />
      </div>
      <DefaultModal
        show={isAddedNew}
        onHide={() => setIsAddedNew(false)}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => setIsAddedNew(false)}
          />
          <div className="text-center add-mosque-modal-content">
            <Icon
              iconName="congratulations"
              style={{ justifyContent: "center" }}
            />
            <div>Congratulations!</div>
            <div>
              We have added the details of the new member. You can make edits to
              the details anytime.
            </div>
            <Box
              display="flex"
              justifyContent="center"
              onClick={() => handleButtonClick()}
            >
              <Button width="40px">Add New Member</Button>
            </Box>
          </div>
        </div>
      </DefaultModal>

      <DefaultModal
        show={isApprovedApproval}
        onHide={() => handleApproval()}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => handleApproval()}
          />
          <div className="text-center">
            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
            <Label
              justifyContent="center"
              fontSize="20px"
              fontWeight={700}
              my="20px"
            >
                Congratulations!
            </Label>
            <Label variant="text3" my="20px" color="#7E7E7E">
                We have added the new member. You can make edits to existing member anytime.
            </Label>
          </div>
        </div>
      </DefaultModal>

      <DefaultModal
        show={isDecline}
        onHide={() => setIsDecline(false)}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => setIsDecline(false)}
          />
          <div className="text-center">
            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
            <Label
              justifyContent="center"
              fontSize="20px"
              fontWeight={700}
              my="20px"
            >
              Decline Member
            </Label>
            <Label variant="text3" my="20px" color="#7E7E7E">
              You will permanently loose this member’s details and other
              information.
            </Label>
            <Box className="test" display="flex" justifyContent="center">
              <Button
                className="test"
                type="outlineColor"
                minWidth="200px !important"
                width="200px !important"
                height="45px !important"
                mr="20px !important"
                onClick={() => setIsDecline(false)}
              >
                Cancel
              </Button>
              <Button
                minWidth="200px !important"
                width="200px !important"
                height="45px !important"
                mr="20px !important"
                onClick={() => handleClickDecline()}
              >
                Decline Member
              </Button>
            </Box>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default AddForm;
