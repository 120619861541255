import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { countryCodeList } from "../../constants/countryConstants";
import { useDispatch, useSelector } from "react-redux";

// import { setOnboardingDetails } from "../../customerAction";
import Dropdown from "../Dropdown/DropDownControlled";

const Selectbox = styled.div`
  width: 80px;
  border-right: 0.5px solid #a0aec063;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;

  .codebox {
    position: absolute;
    height: 20px;
    width: 30px;
    background-color: white;
    padding: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  .flag-img {
    width: 30px;
    height: 16px;
    outline: none;
    position: absolute;
    border-radius: 5px;
    left: 8px;
  }

  select {
    font-family: "Poppins", sans-serif;
    width: 53px;
    height: 20px;
    background: none;
    border: none;
    font-size: 13px;
    font-weight: 300;
    outline: none;
    margin-left: 7px;
  }
`;

const CountryCode = ({ value, setCountryCode, isDisabled }) => {
  const [data, setData] = useState("+31");
  const dispatch = useDispatch();

  useEffect(() => {
    if (value) {
      setData(value);
      // console.log(data);
    }
  }, [value]);

  const handleCountryChange = (e) => {
    if (!isDisabled) {
      setCountryCode(e);
      setData(e);
    }
  };

  const getCountryDropdownOptions = () => {
    return countryCodeList.map((item) => {
      return { label: `${item.name} (${item.code})`, value: item.code };
    });
  };

  return (
    // <Selectbox>
    //   <div className="codebox">{data}</div>
    //   <select
    //     value={data}
    //     defaultValue={data}
    //     name="country-code"
    //     id="country_list"
    //     onChange={handleCountryChange}
    //   >
    //     {countryCodeList.map((item) => (
    //       <option data-countrycode={item?.initials} value={item.code}>
    //         {item.name} ({item.code})
    //       </option>
    //     ))}
    //   </select>
    // </Selectbox>

    <Dropdown
      allowSearch
      border="none"
      boxShadow="none"
      variant="outlinedGreen"
      color="#1A1A1A"
      fontWeight="500"
      height="51px"
      minWidth="85px"
      width="85px"
      top="-18px"
      styles={{
        rightIcon: { right: "20%" },
      }}
      isDisabled={isDisabled}
      selectedValue={data}
      onOptionClick={(item) => handleCountryChange(item?.value)}
      options={getCountryDropdownOptions()}
    />
  );
};

export default CountryCode;
