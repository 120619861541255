import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Banner from "./components/Banner";
import Box from "../../../../components/Box";
import Label from "../../../../components/Label";
import BodyContainer from "./components/BodyContainer";
import ResponseContainer from "../../../../components/SuccessPage/ResponseContainer";
import { useSelector, useDispatch } from "react-redux";
import { RESET_LINK_SUCCESS } from "../../adminActionType";

const ForgetPage = () => {
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    return () => {
      dispatch({
        type: RESET_LINK_SUCCESS,
        isFetching: false,
        isResetLinkSend: false,
      });
    };
  }, []);

  const isResetLinkSend = useSelector(
    (state) => state.adminReducer?.isResetLinkSend
  );

  return (
    <div>
      <Banner link={"/admin/login"} />
      {!isResetLinkSend ? (
        <BodyContainer
          userDetails={userDetails}
          setUserDetails={setUserDetails}
        />
      ) : (
        <ResponseContainer>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Label
              color="#1A1A1A"
              variant="text2"
              fontWeight={700}
              lineHeight="24px"
            >
              Password Reset Link Sent
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="16px"
            >
              We have sent password reset link to
            </Label>
            <Label
              color="#222222c2"
              variant="text5"
              fontWeight={600}
              lineHeight="24px"
              mt="4px"
            >
              {userDetails?.email}
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="12px"
            >
              Kindly open your gmail and click on the link
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="4px"
            >
              sent to you.
            </Label>
          </Box>
        </ResponseContainer>
      )}
    </div>
  );
};

export default ForgetPage;
