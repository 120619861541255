import moment from "moment-timezone";

export const getFormattedTime = (
  time,
  format = "DD MMM, hh:mm a",
  tz = null,
) => {
  if (time) {
    return !tz
      ? moment(time).format(format)
      : moment(time).tz(tz).format(format);
  }
  return null;
};

// Do not specify a timezone, so that the system specific timezone
export const getFormattedTimeNow = (format = "DD MMM, hh:mm a") =>
  moment().format(format);

export function getFormattedUnit(unit) {
  const unitObj = {
    minute: "min",
    minutes: "min",
    hour: "hr",
    hours: "hr",
    day: "day",
    days: "day",
    month: "mon",
    months: "mon",
    year: "yr",
    years: "yr",
  };
  return unitObj[unit];
}

export const getFormattedRelativeTime = (time) => {
  const formattedTime = moment(time).fromNow(true);

  const timeArray = formattedTime.split(" ");

  if (formattedTime === "a few seconds") {
    return `< 1 min`;
  } else if (formattedTime[0] === "a") {
    return `1 ${getFormattedUnit(timeArray[timeArray.length - 1])}`;
  }

  return `${formattedTime && formattedTime.split(" ")[0]} ${
      formattedTime &&
      formattedTime.split(" ")[1] &&
      getFormattedUnit(formattedTime.split(" ")[1])
  }`;
};
