import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import MemberStepOne from "./MemberStepOne";
import MemberStepTwo from "./MemberStepTwo";
import MemberStepThree from "./MemberStepThree";
import {fetchActiveMosques, newMemberOnboard} from "../../../customerAction";
import isEmail from "validator/lib/isEmail";

const StepperContainer = styled.div`
  border-bottom: 1px solid #D9DBE9;
`;

const StepperContainerUi = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  background: #fff;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Status = styled.div`
  width: 35px;
  height: 35px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.active ? "#D0004B" : "#EFF0F6"};
  color: ${props => props.active ? "#FFFFFF" : "#7E7E7E"};
  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
`;

const Separator = styled.div`
  height: 6px;
  width: 30%;
  background: ${props => props.active ? "#D0004B" : "#EFF0F6"};
  border-radius: 40px;
  margin: 0 10px;
  @media (max-width: 768px) {
    height: 3px;
  }
`;

function MemberDetails({ defaultStep = 0 }) {
    const dispatch = useDispatch();
    const [current, setCurrent] = useState(0);
    const [isNext, setIsNext] = useState(false);
    const [isStepOne, setIsStepOne] = useState(false);
    const [isStepTwo, setIsStepTwo] = useState(false);
    const [isStepThree, setIsStepThree] = useState(false);

    useEffect(() => {
        if (defaultStep) {
            setCurrent(defaultStep);
        }
    }, [defaultStep]);

    useEffect(() => {
        dispatch(fetchActiveMosques());
    }, [dispatch])

    const memberDetails = useSelector((state) => state.customerReducer.memberDetails);
    const is_onboarded_member = useSelector((state) => state.customerReducer.is_onboarded_member);
    const is_onboarding_member = useSelector((state) => state.customerReducer.is_onboarding_member);

    const stepOneValidation = () => {
        return !!memberDetails?.contact_name.trim() &&
            !!memberDetails?.contact_email.trim() &&
            !!isEmail(memberDetails?.contact_email.trim()) &&
            !!memberDetails?.address.trim();
    }

    const stepTwoValidation = () => {
        return !!memberDetails?.payment_method.trim() &&
            !!memberDetails?.entity_id;
    }

    const stepThreeValidation = () => {
        return !!memberDetails?.subscription_plan_id;
    }

    useEffect(() => {
        if (is_onboarded_member) {
            setIsStepThree(true);
        }
    }, [is_onboarded_member])

    useEffect(() => {
        if (current === 0) {
            setIsNext(stepOneValidation())
        }
        if (current === 1) {
            setIsNext(stepTwoValidation())
        }
        if (current === 2) {
            setIsNext(stepThreeValidation())
        }
    }, [current, memberDetails])

    const renderComponent = () => {
        switch (current) {
            case 0:
                return <MemberStepOne/>;

            case 1:
                return <MemberStepTwo/>;

            case 2:
                return <MemberStepThree/>;

            default:
                return null;
        }
    }

    const handleSubmit = () => {
        dispatch(newMemberOnboard())
    };

    const next = (step) => {
        setCurrent(current + 1);
        setIsNext(false);
        if (step === 0) {
            setIsStepOne(true);
        }
        if (step === 1) {
            setIsStepTwo(true);
        }
        if (step === 2) {
            setIsStepThree(true);
        }
    };

    const prev = (step) => {
        setCurrent(current - 1);
        if (step === 1) {
            setIsStepOne(false);
            setIsStepTwo(false);
            setIsStepThree(false);
        }
        if (step === 2) {
            setIsStepOne(true);
            setIsStepTwo(false);
            setIsStepThree(false)
        }
    };

    return (
        <div>
            <StepperContainer>
                {/*<OnboardingSteps />*/}

                <StepperContainerUi>
                    <Step>
                        <Status active={current === 0 || isStepOne}>1</Status>
                    </Step>
                    <Separator active={isStepOne}/>
                    <Step>
                        <Status active={current === 1 || isStepTwo}>2</Status>
                    </Step>
                    <Separator active={isStepTwo}/>
                    <Step>
                        <Status active={current === 2}>3</Status>
                    </Step>
                </StepperContainerUi>
            </StepperContainer>

            <div>
                {renderComponent()}
            </div>

            <div className="d-flex justify-content-between flex-row-reverse">
                {current < 2 && (
                    <div className="d-flex justify-content-end"
                         style={{width: '50%'}}>
                        {
                            isNext ?
                                <button className="fill-bt" onClick={() => next(current)}>
                                    Next Step
                                </button>
                                :
                                <button className="gray-bt" disabled>
                                    Next Step
                                </button>
                        }
                    </div>
                )}
                <div
                    className="d-flex justify-content-between"
                    style={!is_onboarded_member && current === 2 ? {width: '100%'} : {width: '50%'}}
                >
                    {!is_onboarded_member && current > 0 && (
                        <button
                            className="prev-bt"
                            style={{margin: "0 8px"}}
                            onClick={() => prev(current)}
                        >
                            Previous Step
                        </button>
                    )}
                    {!is_onboarded_member && current === 2 && (
                        isNext ?
                            <button className="fill-bt" onClick={() => handleSubmit()}>
                                {is_onboarding_member ? "Submitting.." : "Submit"}
                            </button>
                            :
                            <button className="gray-bt" disabled>
                                Submit
                            </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MemberDetails;
