import React, { useState, useEffect } from "react";
import ExistingPlans from "./ExistingPlans";
import AddNewPlan from "./AddNewPlan";
import Box from "../../../../../components/Box";
import EditPlan from "./EditPlan";

function PlanTab() {
  const [selectedSegment, setselectedSegment] = useState("Information");
  const [showComponent, setShowComponent] = useState("initial");

  const setSegment = (segment) => {
    setselectedSegment(segment);
  };

  const pathName = window.location.search;

  useEffect(() => {
    if (pathName === "?new") {
      setselectedSegment("Screen details");
    }
  }, [pathName]);

  return (
    <div>
      <Box display="flex" alignItems="center" cursor="pointer" justifyContent="space-between">
        <Box fontSize="22px" fontWeight={700}>{selectedSegment === "Information" ? showComponent === "edit" ? "Edit Plan" : 'Existing Plans' : "Add New Plan"}</Box>
        <ul className="d-flex plan-modal">
          <li
            className="information col-6"
            style={{
              fontWeight: selectedSegment === "Information" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Information" ? "#D0004B" : "#fff",
              color: selectedSegment === "Information" ? "#fff" : "#D0004B",
              borderRadius:
                selectedSegment === "Information"
                  ? "50px 0px 0px 50px"
                  : "50px 0px 0px 50px",
            }}
            onClick={() => setSegment("Information")}
          >
            Existing Plans{" "}
          </li>
          <li
            className="screen-details col-6"
            style={{
              fontWeight: selectedSegment === "Screen details" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Screen details" ? "#D0004B" : "#fff",
              color: selectedSegment === "Screen details" ? "#fff" : "#D0004B",
              borderRadius:
                selectedSegment === "Screen details"
                  ? "0px 50px 50px 0px"
                  : "0px 50px 50px 0px",
            }}
            onClick={() => setSegment("Screen details")}
          >
            Add New Plan{" "}
          </li>
        </ul>
      </Box>
      <Box mt="25px">
        {selectedSegment === "Information" ? (
         <ExistingPlans
           showComponent={showComponent}
           setShowComponent={setShowComponent}
           setSegment={setSegment} />
          // <EditPlan setSegment={setSegment}/>
        ) : null}
        {selectedSegment === "Screen details" ? (
          <AddNewPlan setSegment={setSegment} />
        ) : null}
      </Box>
    </div>
  );
}

export default PlanTab;
