import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import {CSVReader} from "react-papaparse";
import styled from "styled-components";
import {callApiV2} from "../../util/apiCaller";
import {showNotification} from "../../util/Utility";
import {connect} from 'react-redux';

const StyledAnchor = styled("a")`
  font-weight: bold;
  font-style: italic;
  text-decoration: none;
  color: #0582CA;
`;

let allKeys = ["contact_name", "country_code", "contact_phone", "contact_email", "is_whatsapp_opted"];

class BulkUploadModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploaded_list: [],
            isUploadingData: false,
        };
    }

    getConvertedList = (data) => {
        let list = [];
        let indexMap = {};
        allKeys.forEach((item, index) => {
            indexMap[item] = index + 1;
        })
        if (data && data.length > 0) {
            let title_row = data[0];
            for (let key in title_row.data) {
                indexMap[title_row.data[key]] = parseInt(key);
            }
            for (let i = 0; i < data.length; i++) {
                if (i > 0) {
                    let item = {};
                    allKeys.forEach(keyName => {
                        item[keyName] = data[i].data[indexMap[keyName]];
                    });
                    list.push(item);
                }
            }
        }
        console.log(indexMap, list);
        return list;
    };

    handleOnFileLoad = (data) => {
        console.log(data);
        this.setState({
            uploaded_list: this.getConvertedList(data)
        })
    }

    handlePlanChange = (val) => {
        this.setState({
            selectedPlanId: val
        })
    }

    handleUpload = async () => {
        this.setState({
            isUploadingData: true
        })
        console.log(this.state.uploaded_list);
        this.setState({
            isUploadingData: false
        });
        let errors = [];
        await Promise.allSettled(this.state.uploaded_list.map(async item => {
            let update = {...item};
            update.entity_id = this.props.activeMosqueId;
            update.subscription_plan_id = this.state.selectedPlanId;
            update.created_by_admin = true;
            const res = await callApiV2({
                method: "post",
                endpoint: `onboarding/new-member-signup`,
                body: {
                    customer: update,
                },
                isErrorSuppressed: true,
            });
            if (res.status === "Error") {
                errors.push(res.data.messsage);
            }
        }));
        if (errors.length > 0) {
            showNotification("error", "We had error uploading members");
        } else {
            showNotification("success", "New members created successfully.");
        }
    };

    render() {
        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{margin: "auto"}}
            >
                <div>
                    <div className="file-header" style={{height: "70px"}}>
                        <div className={"text-center"}>
                            <span style={{fontSize: "32px", marginLeft: "10px", color: "#d0004b"}}
                            >
                Bulk Upload
              </span>
                        </div>
                    </div>
                    <div style={{padding: "20px 20px 20px 20px"}}>
                        <div style={{letterSpacing: "0.02em"}}>
                            <label htmlFor="">Select Plan</label>
                            <select
                                onChange={(e) => this.handlePlanChange(e.target.value)}
                                name="" id="">
                                <option value=""></option>
                                {this.props.planList?.map(item => (
                                    <option value={item._id}>{item.plan_name}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{marginTop: "10px", marginBottom: "10px"}}
                        >
                            Upload a .csv file to bulk upload
                        </div>
                        <div
                            className="kiwi-custom-text kiwi-color-black"
                            style={{marginBottom: "10px"}}
                        >
                            CSV should mandatorily contain Phone Number & Country Code in any
                            2 columns
                        </div>
                        <div
                            className="kiwi-custom-text kiwi-color-black"
                            style={{marginBottom: "10px"}}
                        >
                            <StyledAnchor
                                href="#"
                                target="_blank"
                            >
                                This video
                            </StyledAnchor>
                            {` explains how to upload this `}
                            <StyledAnchor
                                href="../assets/csv/sample.csv"
                                download
                            >
                                sample CSV.
                            </StyledAnchor>
                        </div>
                        <div>
                            <CSVReader
                                style={{
                                    dropArea: {
                                        borderColor: "#222",
                                        borderRadius: 0,
                                        height: "100px",
                                    },
                                    dropAreaActive: {
                                        borderColor: "#222",
                                    },
                                    dropFile: {
                                        width: 100,
                                        height: 120,
                                        background: "#ccc",
                                    },
                                    fileSizeInfo: {
                                        color: "#222",
                                        backgroundColor: "#000",
                                        borderRadius: 3,
                                        lineHeight: 1,
                                        marginBottom: "0.5em",
                                        padding: "0 0.4em",
                                    },
                                    fileNameInfo: {
                                        color: "#222",
                                        backgroundColor: "#eee",
                                        borderRadius: 3,
                                        fontSize: 14,
                                        lineHeight: 1,
                                        padding: "0 0.4em",
                                    },
                                    removeButton: {
                                        color: "#222",
                                    },
                                    progressBar: {
                                        backgroundColor: "#222",
                                    },
                                }}
                                header
                                onFileLoad={this.handleOnFileLoad}
                            >
                                <button
                                    className="btn btn-green"
                                    style={{width: "140px", height: "40px"}}
                                >
                                    Upload
                                </button>
                            </CSVReader>
                        </div>
                        {/*{this.props.csv_filename ? (*/}
                        {/*    <div*/}
                        {/*        className="kiwi-color-black kiwi-h4"*/}
                        {/*        style={{ marginTop: "10px" }}*/}
                        {/*    >*/}
                        {/*        {`Your file "${this.props.csv_filename}" has been uploaded.`}*/}
                        {/*    </div>*/}
                        {/*) : null}*/}
                    </div>
                    <Modal.Footer style={{paddingLeft: "30px", paddingRight: "30px"}}>
                        <button
                            style={{ backgroundColor : "#d0004b", color: "white", borderRadius : "4px", padding : "5px"}}
                            disabled={this.state.isUploadingData}
                            onClick={() => this.handleUpload()}>Upload Members</button>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        planList: state.mosqueReducer.memberPlans?.planList
    }
}

export default connect(mapStateToProps)(BulkUploadModal);
