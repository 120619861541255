import React, { useEffect } from "react";
import styled from "styled-components";

import PlanCard from "./components/PlanCard";
import Overview from "./components/Overview";
import PlanMembers from "./components/PlanMembers";
import MemberTable from "./components/MemberTable";
import { useSelector, useDispatch } from "react-redux";
import { fetchAgentDashboardStats } from "../../mosqueAction";

const RightSide = styled.div`
  background: #FAF4FD; 
`;

const Dashboard = () => {
    const dispatch = useDispatch();
    const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);

    useEffect(() => {
        if (activeEntity?._id) {
            dispatch(fetchAgentDashboardStats(activeEntity?._id));
        }
    }, [activeEntity])

    return (
        <div>
            <RightSide>
                <div>
                    <PlanCard/>
                </div>
                <div>
                    <MemberTable
                        blockedViewTitle={"Unpaid Members"}
                        blockedView={"unpaid"}
                        isFilterBlocked={true}
                        preAppliedFilters={null}
                        infoPage={false}
                    />
                </div>
                <div className="row">
                    <Overview/>
                    <PlanMembers/>
                </div>
            </RightSide>
        </div>
    );
}

export default Dashboard;
