import React from "react";

const Banner = ({ link = "#" }) => {
  const handleClick = () => {
    window.location.href = link;
  };

  return (
    <div className="login_banner">
      <div className="container">
        <div className="logo_im" onClick={() => handleClick()}>
          LOGO
        </div>
        <div className="login_content">
          <h3>Welcome!</h3>
          <p>
            Use these awesome forms to login or create new <br />
            account in your project for free.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
