import React, { useState } from "react";
import Label from "../../../../../components/Label";
import { useSelector } from "react-redux";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon";
import styled from "styled-components";
import PieChartMember from "./PieChartMember";
import PieChartMethod from "./PieChartMethod";

const HeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PlanMembers = () => {
  const [isSegmentChange, setIsSegmentChange] = useState(true);
  const dashboardStats = useSelector(
    (state) => state.mosqueReducer?.dashboardStats
  );

  const getDataMember = () => {
    let data = [];
    data.push({
      count: dashboardStats?.total_entity_monthly_count
        ? dashboardStats?.total_entity_monthly_count
        : 0,
      _id: { current_status: "monthly" },
    });
    data.push({
      count: dashboardStats?.total_entity_yearly_count
        ? dashboardStats?.total_entity_yearly_count
        : 0,
      _id: { current_status: "yearly" },
    });
    data.push({
      count: dashboardStats?.total_entity_quarterly_count
        ? dashboardStats?.total_entity_quarterly_count
        : 0,
      _id: { current_status: "quarterly" },
    });
    return data;
  };

  const getDataMethod = () => {
    let data = [];
    data.push({
      count: dashboardStats?.paymentTypeCounts?.auto
        ? dashboardStats?.paymentTypeCounts?.auto
        : 0,
      _id: { current_status: "auto" },
    });
    data.push({
      count: dashboardStats?.paymentTypeCounts?.manual
        ? dashboardStats?.paymentTypeCounts?.manual
        : 0,
      _id: { current_status: "manual" },
    });
    return data;
  };

  return (
    <div className="col-md-5 col-lg-4">
      <div className="plan-members">
        <HeaderBox>
          <Box>
            <Label variant="text1" className="overview-head">Plan Members</Label>
          </Box>
          <Box>
            <Icon
              iconName="flip"
              cursor="pointer"
              onClick={() => setIsSegmentChange(!isSegmentChange)}
            />
          </Box>
        </HeaderBox>
        <Label variant="text5" color="#7E7E7E"  className="overview-disc">
          Total members subscribed
        </Label>
        {isSegmentChange ? (
          <div className="white_box" style={{ height: "100%" }}>
            {dashboardStats?.total_entity_count && (
              <div className="" style={{ height: "100%" }}>
                <div style={{ height: "100%" }}>
                  <PieChartMember
                    pieData={getDataMember()}
                    bg={"white"}
                    padAngle={0}
                    padRadius={0}
                    cornerRadius={0}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="white_box" style={{ height: "100%" }}>
            {dashboardStats?.total_entity_count && (
              <div className="" style={{ height: "100%" }}>
                <div style={{ height: "100%" }}>
                  <PieChartMethod
                    pieData={getDataMethod()}
                    bg={"white"}
                    padAngle={0}
                    padRadius={0}
                    cornerRadius={0}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanMembers;
