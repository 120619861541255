import React from "react";
import Icon from "../../../components/Icon/Icon";
const DeleteComponent = ({ show, handleDelete, setshow, text}) => {

  const handleOnClick = () => {
    setshow(!show);
    handleDelete();
  };

  return (
    <>
       <div className="d-flex justify-content-between">
                <div>

                </div>
                <div onClick={() => setshow(null, false)}>
                    <Icon
                        // style={{zIndex: 2000}}
                        iconName="blackCross"
                    />
                </div>
            </div>
    <div className="congrats-screen">
      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
      <h4>Delete Plan</h4>
      <p>{text}</p>

      <div className="d-flex justify-content-around mt-3">
        <button className="prev-bt" onClick={() => setshow(!show)}>
          Cancel
        </button>
        <button className="fill-bt" onClick={() => handleOnClick()}>
          Delete Plan
        </button>
      </div>
    </div>
    </>
    
  );
};

export default DeleteComponent;
