import React from "react";
import Label from "../../../../../components/Label";
import AreaChart from "../../../../../components/D3Charts/AreaChart";
import { useSelector } from "react-redux";

function OverviewMembers() {
  const dashboardStats = useSelector(state => state.adminReducer?.dashboardStats);

  const getData = () => {
    const data = [];
    if (dashboardStats?.top_mosques_by_members?.length > 0) {
      dashboardStats?.top_mosques_by_members.forEach(element => {
        data.push(element?.total_member_count);
      });
    }
    return data;
  }

  const getCategories = () => {
    const data = [];
    if (dashboardStats?.top_mosques_by_members?.length > 0) {
      dashboardStats?.top_mosques_by_members.forEach(element => {
        data.push(element?.name);
      });
    }
    return data;
  }

  return (
    <div className="plan-members" style={{width: "100%"}}>
      <Label variant="text1">Overview</Label>
      <Label variant="text5" color="#7E7E7E">Total members per mosque</Label>
      <div className="white_box" style={{ height: "100%"}}>
        <div className="" style={{ height: "100%" }}>
          <div>
            <AreaChart
            name="Members"
            data={getData()}
            categories={getCategories()}
            />
          </div>
        </div>

      </div>
    </div>
  );
}

export default OverviewMembers;
