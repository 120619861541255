import React from "react";

const Banner = ({ link = "#" }) => {
  const handleClick = () => {
    window.location.href = link;
  };

  return (
    <div className="login_banner">
      <div className="container">
        <div className="logo_im" onClick={() => handleClick()}>
          <img style={{ height: "100%", width: "100px" }} src="/assets/Logo.png"/>
        </div>
        <div className="login_content">
          <h3>Welcome!</h3>
          <p>
            Enter Email To Reset Password
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
