import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./css/header.css";
import "./css/login.css";
import "./css/admin.css";
import "./css/blog.css";
import "./css/modal.css";
import "./css/addmember.css";
import "./css/antd.css";

import LoginPage from "./modules/Customer/pages/LoginPage/LoginPage";
import HomePage from "./modules/Customer/pages/HomePage/HomePage";

//Admin
import AdminLogin from "./modules/Admin/pages/Login/LoginPage";
import AdminForgetPage from "./modules/Admin/pages/Forget/ForgetPage";
import AdminResetPage from "./modules/Admin/pages/Reset/ResetPage";
import AdminProfile from "./modules/Admin/pages/Profile/AdminProfile";
import AdminDashboard from "./modules/Admin/pages/Dashboard/Dashboard";
import AdminMosqueList from "./modules/Admin/pages/MosqueDetails/MosqueList";
import AdminMosqueAdd from "./modules/Admin/pages/MosqueDetails/MosqueAdd";
import AdminMemberList from "./modules/Admin/pages/Members/MembersListPage";
import AdminAddMember from "./modules/Admin/pages/Members/AddMember";
import AdminSubscriptions from "./modules/Admin/pages/Subscriptions/ContactUsPage";
import AdminFinancials from "./modules/Admin/pages/Financials/AdminFinancials";
import NavbarSidebarAdmin from "./modules/Admin/components/NavbarSidebarMosque/NavbarSidebarAdmin";
import AdminPlanModification from "./modules/Admin/pages/PlanModification/AdminPlanModification";

//Mosque
import Dashboard from "./modules/Mosque/pages/Dashboard/Dashboard";
import MosqueDetails from "./modules/Mosque/pages/MosqueDetails/MosqueDetails";
import MembersListPage from "./modules/Mosque/pages/Members/MembersListPage";
import AddMember from "./modules/Mosque/pages/Members/AddMember";
import Subscriptions from "./modules/Mosque/pages/Subscriptions/PlanModification";
import BlogList from "./modules/Mosque/pages/Blogs/BlogListPage";
import BlogDetailsPage from "./modules/Mosque/pages/Blogs/BlogDetailsPage";
import CampaignsListPage from "./modules/Mosque/pages/Campaigns/CampaignsListPage";
import CampaignDetailsPage from "./modules/Mosque/pages/Campaigns/CampaignDetailsPage";
import Financials from "./modules/Mosque/pages/Financials/FinancialPage";
import ProfilePage from "./modules/Mosque/pages/Profile/MosqueAdminProfile";
import MyPlansPage from "./modules/Mosque/pages/Myplans/MyPlansPage";
import PayNowPage from "./modules/Mosque/pages/PayNow/PayNowPage";
import AdminsPage from "./modules/Mosque/pages/Admins/AdminsPage";
import NavbarSidebarGlobal from "./modules/Mosque/components/NavbarSidebarMosque/NavbarSidebarGlobal";
import RedirectPageApp from "./modules/Mosque/pages/RedirectPage/RedirectPage";

import { ToastContainer } from "react-toastify";
import { fetchCustomerProfile } from "./modules/Customer/customerAction";
import {
  setAuthentication,
  setAuthState,
  setUserId,
  setUserType,
} from "./modules/appAction";

import {
  AUTHENTICATED,
  AUTHENTICATING,
  NOT_AUTHENTICATED,
} from "./constants/authEnums";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";
import {
  APP_TOKEN,
  USER_ID,
  USER_TYPE,
  USER_TYPE_ADMIN,
  USER_TYPE_CUSTOMER,
  USER_TYPE_MOSQUE,
} from "./constants/appEnums";
import RedirectPage from "./modules/Admin/pages/RedirectPage/RedirectPage";
import Fund from "./modules/Mosque/pages/Fund/Fund";
import {
  fetchAdminProfileDetail,
  fetchSaasPlanList,
} from "./modules/Admin/adminAction";
import { fetchAgentProfile } from "./modules/Mosque/mosqueAction";
import IdleTimer from "./components/IdleTimer";

function App() {
  const dispatch = useDispatch();

  const token = localStorage.getItem(APP_TOKEN);
  const userType = localStorage.getItem(USER_TYPE);
  const userId = localStorage.getItem(USER_ID);

  const authState = useSelector((state) => state?.appReducer?.authState);

  const handleLogOut = () => {
    const user_type = localStorage.getItem(USER_TYPE);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("user_type");
    localStorage.removeItem("_expiredTime");
    if (user_type === USER_TYPE_ADMIN) {
      window.location.href = "/admin/login";
    } else {
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 600, //expire after 10 seconds
      onTimeout: () => {
        handleLogOut();
      },
      onExpired: () => {
        // do something if expired on load
        handleLogOut();
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    try {
      dispatch(setAuthState(AUTHENTICATING));
      // get user from the payload of jwt token
      if (token) {
        dispatch(setAuthState(AUTHENTICATED));
        dispatch(setAuthentication(true));
        dispatch(setUserId(userId));
        dispatch(setUserType(userType));
        if (userType === USER_TYPE_CUSTOMER) {
          dispatch(fetchCustomerProfile(userId));
        } else if (userType === USER_TYPE_ADMIN) {
          dispatch(fetchAdminProfileDetail(userId));
        } else if (userType === USER_TYPE_MOSQUE) {
          dispatch(fetchAgentProfile(userId));
        }
      } else {
        dispatch(setAuthState(NOT_AUTHENTICATED));
        dispatch(setAuthentication(false));
      }
    } catch (err) {
      dispatch(setAuthState(NOT_AUTHENTICATED));
      dispatch(setAuthentication(false));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchSaasPlanList());
  }, [dispatch]);

  const AuthenticatedRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path={"login"}
            element={
              <RedirectPageApp
                isCustomer={userType === USER_TYPE_CUSTOMER}
                isMosque={userType === USER_TYPE_MOSQUE}
                isAdmin={userType === USER_TYPE_ADMIN}
              />
            }
          />

          {/* Mosque */}
          <Route
            path={"mosque"}
            element={
              <NavbarSidebarGlobal>
                <Outlet />
              </NavbarSidebarGlobal>
            }
          >
            <Route path={":id/dashboard"} element={<Dashboard />}></Route>
            <Route path={":id/details"} element={<MosqueDetails />}></Route>
            <Route path={":id/info"} element={<MembersListPage />}></Route>
            <Route path={":id/members"} element={<MembersListPage />}></Route>
            <Route path={":id/members/add"} element={<AddMember />}></Route>
            <Route path={":id/fund"} element={<Fund />}></Route>
            <Route
              path={":id/subscriptions"}
              element={<Subscriptions />}
            ></Route>
            <Route path={":id/financials"} element={<Financials />}></Route>
            <Route
              path={":id/campaigns"}
              element={<CampaignsListPage />}
            ></Route>
            <Route
              path={":id/campaigns/:campaign_id"}
              element={<CampaignDetailsPage />}
            ></Route>
            <Route path={":id/blogs"} element={<BlogList />}></Route>
            <Route
              path={":id/blogs/:blog_id/edit"}
              element={<BlogList />}
            ></Route>
            <Route
              path={":id/blogs/:url/info"}
              element={<BlogDetailsPage editMode={"false"} />}
            ></Route>
            <Route
              path={":id/blogs/:url/edit"}
              element={<BlogDetailsPage editMode={"true"} />}
            ></Route>
            <Route path={":id/profile"} element={<ProfilePage />}></Route>
            <Route path={":id/my-plans"} element={<MyPlansPage />}></Route>
            <Route path={":id/pay-now"} element={<PayNowPage />}></Route>
            <Route path={":id/admins"} element={<AdminsPage />}></Route>
          </Route>
          {/* Admin */}
          <Route
            path={"/admin"}
            element={
              <NavbarSidebarAdmin>
                <Outlet />
              </NavbarSidebarAdmin>
            }
          >
            <Route path={"forget"} element={<AdminForgetPage />}></Route>
            <Route path={"reset-password"} element={<AdminResetPage />}></Route>
            <Route path={"login"} element={<RedirectPage />} />
            <Route path={"dashboard"} element={<AdminDashboard />}></Route>
            <Route path={"info"} element={<AdminProfile />}></Route>
            <Route path={"members"} element={<AdminMemberList />}></Route>
            <Route path={"mosque/add"} element={<AdminMosqueAdd />}></Route>
            <Route path={"members/info"} element={<AdminAddMember />}></Route>
            <Route path={"mosques"} element={<AdminMosqueList />}></Route>
            <Route
              path={"planModification"}
              element={<AdminPlanModification />}
            ></Route>
            <Route
              path={"subscriptions"}
              element={<AdminSubscriptions />}
            ></Route>
            <Route path={"financials"} element={<AdminFinancials />}></Route>
            <Route path={"profile"} element={<AdminProfile />}></Route>
          </Route>
        </Routes>
      </Router>
    );
  };

  const NotAuthenticatedRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path={"/admin/login"} element={<AdminLogin />}></Route>
          <Route path={"/admin/forget"} element={<AdminForgetPage />}></Route>
          <Route
            path={"/admin/reset-password"}
            element={<AdminResetPage />}
          ></Route>
          <Route
            path={"/mosque/:id/:id"}
            element={<RedirectPage isMosque />}
          ></Route>
          <Route
            path={"/customer/:id/:id"}
            element={<RedirectPage isCustomer />}
          ></Route>
          <Route path={"/admin/:id"} element={<RedirectPage isAdmin />}></Route>
        </Routes>
      </Router>
    );
  };

  const getRoutes = () => {
    switch (authState) {
      case AUTHENTICATED:
        return <AuthenticatedRoutes />;
      case NOT_AUTHENTICATED:
        return <NotAuthenticatedRoutes />;
      case AUTHENTICATING:
        return <FullPageLoader />;
      default:
        return <FullPageLoader />;
    }
  };

  return (
    <React.Fragment>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Suspense fallback={<FullPageLoader />}>{getRoutes()}</Suspense>
        </ThemeProvider>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
}

export default App;
