export const countryCodeList = [
    {
        name: "Netherlands",
        code: "+31",
        uniCode: "",
        initials: "NL",
    },
    {
        name: "Netherlands Antilles",
        code: "+599",
        uniCode: "",
        initials: "AN",
    },
    {
        name: "Afghanistan",
        code: "+93",
        uniCode: "",
        initials: "AF",
    },
    {
        name: "Iran",
        code: "+98",
        uniCode: "",
        initials: "IR",
    },
    {
        name: "Albania",
        code: "+355",
        uniCode: "",
        initials: "AL",
    },
    {
        name: "Algeria",
        code: "+213",
        uniCode: "",
        initials: "DZ",
    },
    {
        name: "American Samoa",
        code: "+1",
        uniCode: "",
        initials: "AS",
    },
    {
        name: "Andorra",
        code: "+376",
        uniCode: "",
        initials: "AD",
    },
    {
        name: "Angola",
        code: "+244",
        uniCode: "",
        initials: "AO",
    },
    {
        name: "Anguilla",
        code: "+1",
        uniCode: "",
        initials: "AI",
    },
    {
        name: "Antigua & Barbuda",
        code: "+1",
        uniCode: "",
        initials: "AG",
    },
    {
        name: "Argentina",
        code: "+54",
        uniCode: "",
        initials: "AR",
    },
    {
        name: "Armenia",
        code: "+374",
        uniCode: "",
        initials: "AM",
    },
    {
        name: "Aruba",
        code: "+297",
        uniCode: "",
        initials: "AW",
    },
    {
        name: "Australia",
        code: "+61",
        uniCode: "",
        initials: "AU",
    },
    {
        name: "Austria",
        code: "+43",
        uniCode: "",
        initials: "AT",
    },
    {
        name: "Azerbaijan",
        code: "+994",
        uniCode: "",
        initials: "AZ",
    },
    {
        name: "Bahamas",
        code: "+1",
        uniCode: "",
        initials: "BS",
    },
    {
        name: "Bahrain",
        code: "+973",
        uniCode: "",
        initials: "BH",
    },
    {
        name: "Bangladesh",
        code: "+880",
        uniCode: "",
        initials: "BD",
    },
    {
        name: "Barbados",
        code: "+1",
        uniCode: "",
        initials: "BB",
    },
    {
        name: "Belarus",
        code: "+375",
        uniCode: "",
        initials: "BY",
    },
    {
        name: "Belgium",
        code: "+32",
        uniCode: "",
        initials: "BE",
    },
    {
        name: "Belize",
        code: "+501",
        uniCode: "",
        initials: "BZ",
    },
    {
        name: "Benin",
        code: "+229",
        uniCode: "",
        initials: "DY",
    },
    {
        name: "Bermuda",
        code: "+1",
        uniCode: "",
        initials: "BM",
    },
    {
        name: "Bhutan",
        code: "+975",
        uniCode: "",
        initials: "BT",
    },
    {
        name: "Bolivia",
        code: "+591",
        uniCode: "",
        initials: "BO",
    },
    {
        name: "Bosnia & Herzegovina",
        code: "+387",
        uniCode: "",
        initials: "BA",
    },
    {
        name: "Botswana",
        code: "+267",
        uniCode: "",
        initials: "BW",
    },
    {
        name: "Brazil",
        code: "+55",
        uniCode: "",
        initials: "BR",
    },
    {
        name: "British Indian Ocean Territory",
        code: "+246",
        uniCode: "",
        initials: "IO",
    },
    {
        name: "British Virgin Islands",
        code: "+1",
        uniCode: "",
        initials: "VG",
    },
    {
        name: "Brunei",
        code: "+673",
        uniCode: "",
        initials: "BN",
    },
    {
        name: "Bulgaria",
        code: "+359",
        uniCode: "",
        initials: "BG",
    },
    {
        name: "Burkina Faso",
        code: "+226",
        uniCode: "",
        initials: "HV",
    },
    {
        name: "Burundi",
        code: "+257",
        uniCode: "",
        initials: "BI",
    },
    {
        name: "Cambodia",
        code: "+855",
        uniCode: "",
        initials: "KH",
    },
    {
        name: "Cameroon",
        code: "+237",
        uniCode: "",
        initials: "CM",
    },
    {
        name: "Canada",
        code: "+1",
        uniCode: "",
        initials: "CA",
    },
    {
        name: "Cape Verde",
        code: "+238",
        uniCode: "",
        initials: "CV",
    },
    {
        name: "Cayman Islands",
        code: "+1",
        uniCode: "",
        initials: "KY",
    },
    {
        name: "Central African Republic",
        code: "+236",
        uniCode: "",
        initials: "CF",
    },
    {
        name: "Chad",
        code: "+235",
        uniCode: "",
        initials: "TD",
    },
    {
        name: "Chile",
        code: "+56",
        uniCode: "",
        initials: "CL",
    },
    {
        name: "Christmas Island",
        code: "+61",
        uniCode: "",
        initials: "CX",
    },
    {
        name: "Cocos Islands",
        code: "+61",
        uniCode: "",
        initials: "CC",
    },
    {
        name: "Colombia",
        code: "+57",
        uniCode: "",
        initials: "CO",
    },
    {
        name: "Comoros",
        code: "+269",
        uniCode: "",
        initials: "KM",
    },
    {
        name: "Cook Islands",
        code: "+682",
        uniCode: "",
        initials: "CK",
    },
    {
        name: "Costa Rica",
        code: "+506",
        uniCode: "",
        initials: "CR",
    },
    {
        name: "Croatia",
        code: "+385",
        uniCode: "",
        initials: "HR",
    },
    {
        name: "Curacao",
        code: "+599",
        uniCode: "",
        initials: "CW",
    },
    {
        name: "Cyprus",
        code: "+357",
        uniCode: "",
        initials: "CY",
    },
    {
        name: "Czech Republic",
        code: "+420",
        uniCode: "",
        initials: "CZ",
    },
    {
        name: "Democratic Republic of the Congo",
        code: "+243",
        uniCode: "",
        initials: "CD",
    },
    {
        name: "Denmark",
        code: "+45",
        uniCode: "",
        initials: "DK",
    },
    {
        name: "Djibouti",
        code: "+253",
        uniCode: "",
        initials: "DJ",
    },
    {
        name: "Dominica",
        code: "+1",
        uniCode: "",
        initials: "DM",
    },
    {
        name: "Dominican Republic",
        code: "+1",
        uniCode: "",
        initials: "DO",
    },
    {
        name: "East Timor",
        code: "+670",
        uniCode: "",
        initials: "TL",
    },
    {
        name: "Ecuador",
        code: "+593",
        uniCode: "",
        initials: "EC",
    },
    {
        name: "Egypt",
        code: "+20",
        uniCode: "",
        initials: "EG",
    },
    {
        name: "El Salvador",
        code: "+503",
        uniCode: "",
        initials: "SV",
    },
    {
        name: "Equatorial Guinea",
        code: "+240",
        uniCode: "",
        initials: "GQ",
    },
    {
        name: "Eritrea",
        code: "+291",
        uniCode: "",
        initials: "ER",
    },
    {
        name: "Estonia",
        code: "+372",
        uniCode: "",
        initials: "EE",
    },
    {
        name: "Ethiopia",
        code: "+251",
        uniCode: "",
        initials: "ET",
    },
    {
        name: "Falkland Islands",
        code: "+500",
        uniCode: "",
        initials: "FK",
    },
    {
        name: "Faroe Islands",
        code: "+298",
        uniCode: "",
        initials: "FO",
    },
    {
        name: "Fiji",
        code: "+679",
        uniCode: "",
        initials: "FJ",
    },
    {
        name: "Finland",
        code: "+358",
        uniCode: "",
        initials: "FI",
    },
    {
        name: "France",
        code: "+33",
        uniCode: "",
        initials: "FX",
    },
    {
        name: "French Polynesia",
        code: "+689",
        uniCode: "",
        initials: "PF",
    },
    {
        name: "Gabon",
        code: "+241",
        uniCode: "",
        initials: "GA",
    },
    {
        name: "Gambia",
        code: "+220",
        uniCode: "",
        initials: "GM",
    },
    {
        name: "Georgia",
        code: "+995",
        uniCode: "",
        initials: "GE",
    },
    {
        name: "Germany",
        code: "+49",
        uniCode: "",
        initials: "DE",
    },
    {
        name: "Ghana",
        code: "+233",
        uniCode: "",
        initials: "GH",
    },
    {
        name: "Gibraltar",
        code: "+350",
        uniCode: "",
        initials: "GI",
    },
    {
        name: "Greece",
        code: "+30",
        uniCode: "",
        initials: "GR",
    },
    {
        name: "Greenland",
        code: "+299",
        uniCode: "",
        initials: "GL",
    },
    {
        name: "Grenada",
        code: "+1",
        uniCode: "",
        initials: "GD",
    },
    {
        name: "Guam",
        code: "+1",
        uniCode: "",
        initials: "GU",
    },
    {
        name: "Guatemala",
        code: "+502",
        uniCode: "",
        initials: "GT",
    },
    {
        name: "Guernsey",
        code: "+44",
        uniCode: "",
        initials: "GG",
    },
    {
        name: "Guinea",
        code: "+224",
        uniCode: "",
        initials: "GN",
    },
    {
        name: "Guinea-Bissau",
        code: "+245",
        uniCode: "",
        initials: "GW",
    },
    {
        name: "Guyana",
        code: "+592",
        uniCode: "",
        initials: "GY",
    },
    {
        name: "Haiti",
        code: "+509",
        uniCode: "",
        initials: "HT",
    },
    {
        name: "Honduras",
        code: "+504",
        uniCode: "",
        initials: "HN",
    },
    {
        name: "Hong Kong",
        code: "+852",
        uniCode: "",
        initials: "HK",
    },
    {
        name: "Hungary",
        code: "+36",
        uniCode: "",
        initials: "HU",
    },
    {
        name: "Iceland",
        code: "+354",
        uniCode: "",
        initials: "IS",
    },
    {
        name: "India",
        code: "+91",
        uniCode: "",
        initials: "IN",
    },
    {
        name: "Indonesia",
        code: "+62",
        uniCode: "",
        initials: "ID",
    },
    {
        name: "Iraq",
        code: "+964",
        uniCode: "",
        initials: "IQ",
    },
    {
        name: "Ireland",
        code: "+353",
        uniCode: "",
        initials: "IE",
    },
    {
        name: "Isle of Man",
        code: "+44",
        uniCode: "",
        initials: "IM",
    },
    {
        name: "Israel",
        code: "+972",
        uniCode: "",
        initials: "IL",
    },
    {
        name: "Italy",
        code: "+39",
        uniCode: "",
        initials: "IT",
    },
    {
        name: "Ivory Coast",
        code: "+225",
        uniCode: "",
        initials: "CI",
    },
    {
        name: "Jamaica",
        code: "+1",
        uniCode: "",
        initials: "JM",
    },
    {
        name: "Japan",
        code: "+81",
        uniCode: "",
        initials: "JP",
    },
    {
        name: "Jersey",
        code: "+44",
        uniCode: "",
        initials: "JE",
    },
    {
        name: "Jordan",
        code: "+962",
        uniCode: "",
        initials: "JO",
    },
    {
        name: "Kazakhstan",
        code: "+7",
        uniCode: "",
        initials: "KZ",
    },
    {
        name: "Kenya",
        code: "+254",
        uniCode: "",
        initials: "KE",
    },
    {
        name: "Kiribati",
        code: "+686",
        uniCode: "",
        initials: "KI",
    },
    {
        name: "Kosovo",
        code: "+383",
        uniCode: "",
        initials: "XK",
    },
    {
        name: "Kuwait",
        code: "+965",
        uniCode: "",
        initials: "KW",
    },
    {
        name: "Kyrgyzstan",
        code: "+996",
        uniCode: "",
        initials: "KG",
    },
    {
        name: "Laos",
        code: "+856",
        uniCode: "",
        initials: "LA",
    },
    {
        name: "Latvia",
        code: "+371",
        uniCode: "",
        initials: "LV",
    },
    {
        name: "Lebanon",
        code: "+961",
        uniCode: "",
        initials: "LB",
    },
    {
        name: "Lesotho",
        code: "+266",
        uniCode: "",
        initials: "LS",
    },
    {
        name: "Liberia",
        code: "+231",
        uniCode: "",
        initials: "LR",
    },
    {
        name: "Libya",
        code: "+218",
        uniCode: "",
        initials: "LY",
    },
    {
        name: "Liechtenstein",
        code: "+423",
        uniCode: "",
        initials: "LI",
    },
    {
        name: "Lithuania",
        code: "+370",
        uniCode: "",
        initials: "LT",
    },
    {
        name: "Luxembourg",
        code: "+352",
        uniCode: "",
        initials: "LU",
    },
    {
        name: "Macau",
        code: "+853",
        uniCode: "",
        initials: "MO",
    },
    {
        name: "Macedonia",
        code: "+389",
        uniCode: "",
        initials: "MK",
    },
    {
        name: "Madagascar",
        code: "+261",
        uniCode: "",
        initials: "MG",
    },
    {
        name: "Malawi",
        code: "+265",
        uniCode: "",
        initials: "MW",
    },
    {
        name: "Malaysia",
        code: "+60",
        uniCode: "",
        initials: "MY",
    },
    {
        name: "Maldives",
        code: "+960",
        uniCode: "",
        initials: "MV",
    },
    {
        name: "Mali",
        code: "+223",
        uniCode: "",
        initials: "ML",
    },
    {
        name: "Malta",
        code: "+356",
        uniCode: "",
        initials: "MT",
    },
    {
        name: "Marshall Islands",
        code: "+692",
        uniCode: "",
        initials: "MH",
    },
    {
        name: "Mauritania",
        code: "+222",
        uniCode: "",
        initials: "MR",
    },
    {
        name: "Mauritius",
        code: "+230",
        uniCode: "",
        initials: "MU",
    },
    {
        name: "Mayotte",
        code: "+262",
        uniCode: "",
        initials: "YT",
    },
    {
        name: "Mexico",
        code: "+52",
        uniCode: "",
        initials: "MX",
    },
    {
        name: "Micronesia",
        code: "+691",
        uniCode: "",
        initials: "FM",
    },
    {
        name: "Moldova",
        code: "+373",
        uniCode: "",
        initials: "MD",
    },
    {
        name: "Monaco",
        code: "+377",
        uniCode: "",
        initials: "MC",
    },
    {
        name: "Mongolia",
        code: "+976",
        uniCode: "",
        initials: "MN",
    },
    {
        name: "Montenegro",
        code: "+382",
        uniCode: "",
        initials: "ME",
    },
    {
        name: "Montserrat",
        code: "+1",
        uniCode: "",
        initials: "MS",
    },
    {
        name: "Morocco",
        code: "+212",
        uniCode: "",
        initials: "MA",
    },
    {
        name: "Mozambique",
        code: "+258",
        uniCode: "",
        initials: "MZ",
    },
    {
        name: "Myanmar",
        code: "+95",
        uniCode: "",
        initials: "MM",
    },
    {
        name: "Namibia",
        code: "+264",
        uniCode: "",
        initials: "NA",
    },
    {
        name: "Nauru",
        code: "+674",
        uniCode: "",
        initials: "NR",
    },
    {
        name: "Nepal",
        code: "+977",
        uniCode: "",
        initials: "NP",
    },
    {
        name: "New Caledonia",
        code: "+687",
        uniCode: "",
        initials: "NC",
    },
    {
        name: "New Zealand",
        code: "+64",
        uniCode: "",
        initials: "NZ",
    },
    {
        name: "Nicaragua",
        code: "+505",
        uniCode: "",
        initials: "NI",
    },
    {
        name: "Niger",
        code: "+227",
        uniCode: "",
        initials: "NE",
    },
    {
        name: "Nigeria",
        code: "+234",
        uniCode: "",
        initials: "NG",
    },
    {
        name: "Niue",
        code: "+683",
        uniCode: "",
        initials: "NU",
    },
    {
        name: "Northern Mariana Islands",
        code: "+1",
        uniCode: "",
        initials: "MP",
    },
    {
        name: "Norway",
        code: "+47",
        uniCode: "",
        initials: "NO",
    },
    {
        name: "Oman",
        code: "+968",
        uniCode: "",
        initials: "OM",
    },
    {
        name: "Pakistan",
        code: "+92",
        uniCode: "",
        initials: "PK",
    },
    {
        name: "Palau",
        code: "+680",
        uniCode: "",
        initials: "PW",
    },
    {
        name: "Palestine",
        code: "+970",
        uniCode: "",
        initials: "PS",
    },
    {
        name: "Panama",
        code: "+507",
        uniCode: "",
        initials: "PA",
    },
    {
        name: "Papua New Guinea",
        code: "+675",
        uniCode: "",
        initials: "PG",
    },
    {
        name: "Paraguay",
        code: "+595",
        uniCode: "",
        initials: "PY",
    },
    {
        name: "Peru",
        code: "+51",
        uniCode: "",
        initials: "PE",
    },
    {
        name: "Philippines",
        code: "+63",
        uniCode: "",
        initials: "PH",
    },
    {
        name: "Pitcairn",
        code: "+64",
        uniCode: "",
        initials: "PN",
    },
    {
        name: "Poland",
        code: "+48",
        uniCode: "",
        initials: "PL",
    },
    {
        name: "Portugal",
        code: "+351",
        uniCode: "",
        initials: "PT",
    },
    {
        name: "Puerto Rico",
        code: "+1",
        uniCode: "",
        initials: "PR",
    },
    {
        name: "Qatar",
        code: "+974",
        uniCode: "",
        initials: "QA",
    },
    {
        name: "Republic of the Congo",
        code: "+242",
        uniCode: "",
        initials: "CD",
    },
    {
        name: "Reunion",
        code: "+262",
        uniCode: "",
        initials: "RE",
    },
    {
        name: "Romania",
        code: "+40",
        uniCode: "",
        initials: "RO",
    },
    {
        name: "Russia",
        code: "+7",
        uniCode: "",
        initials: "SU",
    },
    {
        name: "Rwanda",
        code: "+250",
        uniCode: "",
        initials: "RW",
    },
    {
        name: "Saint Barthelemy",
        code: "+590",
        uniCode: "",
        initials: "BL",
    },
    {
        name: "Saint Helena",
        code: "+290",
        uniCode: "",
        initials: "SH",
    },
    {
        name: "Saint Kitts & Nevis",
        code: "+1",
        uniCode: "",
        initials: "KN",
    },
    {
        name: "Saint Lucia",
        code: "+1",
        uniCode: "",
        initials: "LC",
    },
    {
        name: "Saint Martin",
        code: "+590",
        uniCode: "",
        initials: "MF",
    },
    {
        name: "Saint Pierre & Miquelon",
        code: "+508",
        uniCode: "",
        initials: "PM",
    },
    {
        name: "Saint Vincent & the Grenadines",
        code: "+1",
        uniCode: "",
        initials: "VC",
    },
    {
        name: "Samoa",
        code: "+685",
        uniCode: "",
        initials: "WS",
    },
    {
        name: "San Marino",
        code: "+378",
        uniCode: "",
        initials: "SM",
    },
    {
        name: "Sao Tome & Principe",
        code: "+239",
        uniCode: "",
        initials: "ST",
    },
    {
        name: "Saudi Arabia",
        code: "+966",
        uniCode: "",
        initials: "SA",
    },
    {
        name: "Senegal",
        code: "+221",
        uniCode: "",
        initials: "SN",
    },
    {
        name: "Serbia",
        code: "+381",
        uniCode: "",
        initials: "YU",
    },
    {
        name: "Seychelles",
        code: "+248",
        uniCode: "",
        initials: "SC",
    },
    {
        name: "Sierra Leone",
        code: "+232",
        uniCode: "",
        initials: "SL",
    },
    {
        name: "Singapore",
        code: "+65",
        uniCode: "",
        initials: "SG",
    },
    {
        name: "Sint Maarten",
        code: "+1",
        uniCode: "",
        initials: "SX",
    },
    {
        name: "Slovakia",
        code: "+421",
        uniCode: "",
        initials: "SK",
    },
    {
        name: "Slovenia",
        code: "+386",
        uniCode: "",
        initials: "SI",
    },
    {
        name: "Solomon Islands",
        code: "+677",
        uniCode: "",
        initials: "SB",
    },
    {
        name: "Somalia",
        code: "+252",
        uniCode: "",
        initials: "SO",
    },
    {
        name: "South Africa",
        code: "+27",
        uniCode: "",
        initials: "ZA",
    },
    {
        name: "South Korea",
        code: "+82",
        uniCode: "",
        initials: "KR",
    },
    {
        name: "South Sudan",
        code: "+211",
        uniCode: "",
        initials: "SS",
    },
    {
        name: "Spain",
        code: "+34",
        uniCode: "",
        initials: "ES",
    },
    {
        name: "Sri Lanka",
        code: "+94",
        uniCode: "",
        initials: "LK",
    },
    {
        name: "Sudan",
        code: "+249",
        uniCode: "",
        initials: "SD",
    },
    {
        name: "Suriname",
        code: "+597",
        uniCode: "",
        initials: "SR",
    },
    {
        name: "Svalbard & Jan Mayen",
        code: "+47",
        uniCode: "",
        initials: "SJ",
    },
    {
        name: "Swaziland",
        code: "+268",
        uniCode: "",
        initials: "SZ",
    },
    {
        name: "Sweden",
        code: "+46",
        uniCode: "",
        initials: "SE",
    },
    {
        name: "Switzerland",
        code: "+41",
        uniCode: "",
        initials: "CH",
    },
    {
        name: "Taiwan",
        code: "+886",
        uniCode: "",
        initials: "TW",
    },
    {
        name: "Tajikistan",
        code: "+992",
        uniCode: "",
        initials: "TJ",
    },
    {
        name: "Tanzania",
        code: "+255",
        uniCode: "",
        initials: "TZ",
    },
    {
        name: "Thailand",
        code: "+66",
        uniCode: "",
        initials: "TH",
    },
    {
        name: "Togo",
        code: "+228",
        uniCode: "",
        initials: "TG",
    },
    {
        name: "Tokelau",
        code: "+690",
        uniCode: "",
        initials: "TK",
    },
    {
        name: "Tonga",
        code: "+676",
        uniCode: "",
        initials: "TO",
    },
    {
        name: "Trinidad & Tobago",
        code: "+1",
        uniCode: "",
        initials: "TT",
    },
    {
        name: "Tunisia",
        code: "+216",
        uniCode: "",
        initials: "TN",
    },
    {
        name: "Turkey",
        code: "+90",
        uniCode: "",
        initials: "TR",
    },
    {
        name: "Turkmenistan",
        code: "+993",
        uniCode: "",
        initials: "TM",
    },
    {
        name: "Turks & Caicos Islands",
        code: "+1",
        uniCode: "",
        initials: "TC",
    },
    {
        name: "Tuvalu",
        code: "+688",
        uniCode: "",
        initials: "TV",
    },
    {
        name: "U.S. Virgin Islands",
        code: "+1",
        uniCode: "",
        initials: "VI",
    },
    {
        name: "Uganda",
        code: "+256",
        uniCode: "",
        initials: "UG",
    },
    {
        name: "Ukraine",
        code: "+380",
        uniCode: "",
        initials: "UA",
    },
    {
        name: "United Arab Emirates",
        code: "+971",
        uniCode: "",
        initials: "AE",
    },
    {
        name: "United Kingdom",
        code: "+44",
        uniCode: "",
        initials: "UK",
    },
    {
        name: "United States",
        code: "+1",
        uniCode: "",
        initials: "US",
    },
    {
        name: "Uruguay",
        code: "+598",
        uniCode: "",
        initials: "UY",
    },
    {
        name: "Uzbekistan",
        code: "+998",
        uniCode: "",
        initials: "UZ",
    },
    {
        name: "Vanuatu",
        code: "+678",
        uniCode: "",
        initials: "VU",
    },
    {
        name: "Vatican",
        code: "+379",
        uniCode: "",
        initials: "VA",
    },
    {
        name: "Venezuela",
        code: "+58",
        uniCode: "",
        initials: "VE",
    },
    {
        name: "Vietnam",
        code: "+84",
        uniCode: "",
        initials: "VN",
    },
    {
        name: "Wallis & Futuna",
        code: "+681",
        uniCode: "",
        initials: "WF",
    },
    {
        name: "Western Sahara",
        code: "+212",
        uniCode: "",
        initials: "EH",
    },
    {
        name: "Yemen",
        code: "+967",
        uniCode: "",
        initials: "YE",
    },
    {
        name: "Zambia",
        code: "+260",
        uniCode: "",
        initials: "ZM",
    },
    {
        name: "Zimbabwe",
        code: "+263",
        uniCode: "",
        initials: "ZW",
    },
];
