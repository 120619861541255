import React, { useState, useEffect } from "react";
import styled from "styled-components";

import AddForm from "./components/AddForm";
import { useLocation } from "react-router-dom";
import { callApiV2 } from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/Utility";

const RightSide = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AddMember = () => {
  const query = useQuery();

  const [details, setDetails] = useState();

  const memberSubId = query.get("memberSubId");
  const approvalId = query.get("approvalId");

  useEffect(() => {
    if (memberSubId) {
      callApiCustomer();
    }
  }, [memberSubId]);

  const callApiCustomer = async () => {
    const res = await callApiV2({
      method: "get",
      endpoint: `member_subscription/${memberSubId}/details`,
    });
    if (res?.status === "Success") {
      setDetails(res?.gotCustomer);
    } else {
      showNotification('error', "Something went wrong...");
    }
  }

  return (
    <div className="grid-sec">
      <RightSide open={true}>
        <div className="margin-sec">
          <AddForm details={details} memberSubId={memberSubId} approvalId={approvalId} />
        </div>
      </RightSide>
    </div>
  );
};

export default AddMember;
