import React, { useState } from "react";
import Banner from "./components/Banner";
import Box from "../../../../components/Box";
import Label from "../../../../components/Label";
import BodyContainer from "./components/BodyContainer";
import ResponseContainer from "../../../../components/SuccessPage/ResponseContainer";

function PayNowPage() {
  const [isSuccess, setIsSuccess] = useState(false);
  return (
    <div>
      <Banner />
      {!isSuccess ? (
        <BodyContainer />
      ) : (
        <ResponseContainer>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Label
              color="#1A1A1A"
              variant="text2"
              fontWeight={700}
              lineHeight="24px"
            >
              Payment Successful
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="16px"
            >
              Lorem Ipsum is simply dummy text of the <br />
              printing and typesetting industry.
            </Label>
          </Box>
        </ResponseContainer>
      )}
    </div>
  );
}

export default PayNowPage;
