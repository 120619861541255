import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons/Button";
import {
  agentLogin,
  validateOtpForAgent, setOtpSent,
} from "../../../../Mosque/mosqueAction";
import truncate from "lodash/truncate";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Label from "../../../../../components/Label";
import Box from "../../../../../components/Box";
import { VALIDATE_OTP } from "../../../../Mosque/mosqueActionType";

const AgentNotFountText = styled.div`
  color: #fe1717;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
`;

const MosqueLogin = () => {
  const dispatch = useDispatch();

  let timer;
  const sendOtpDetail = useSelector(
    (state) => state?.customerReducer?.signUp_data
  );

  const is_agent_found = useSelector(
    (state) => state?.mosqueReducer?.is_agent_found
  );

  const isOtpSent = useSelector((state) => state?.mosqueReducer?.isOtpSent);
  const otp_error = useSelector((state) => state?.mosqueReducer?.otp_error);

  const user_id = useSelector(
    (state) => state?.customerReducer?.validate_data?.customer?._id
  );
  const isSentOtp = sendOtpDetail?.is_otp_sent;

  const [modalView, setModalView] = useState("login-page");

  useEffect(() => {
    if (isOtpSent) {
      setModalView("otp-page");
    } else {
      setModalView("login-page");
    }
  });

  const [user, setUserData] = useState({
    contact_email: "",
  });

  const [seconds, setSeconds] = useState("60");

  const [minutes, setMinutes] = useState("0");

  const [otpInput, setOtpInput] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
  });

  const [errors, setErrors] = useState({});

  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();
  const forthInput = useRef();

  const handleOnChange = (keyName, keyValue) => {
    dispatch({
      type: VALIDATE_OTP,
      isFetching: true,
    });
    const maxLength = 1; // Set the desired maximum length

    const otpDetails = {...otpInput};

    if (keyValue.length > maxLength) {
      return null;
    } else {
      otpDetails[keyName] = keyValue
      setOtpInput(otpDetails)
    }
  }

  useEffect(() => {
    timer =
      seconds > 0 &&
      setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  useEffect(() => {
    if (user_id) {
      setModalView("success-page");
    }
  }, [user_id]);

  useEffect(() => {
    if (isSentOtp) {
      setModalView("otp-page");
    }
  }, [isSentOtp]);

  const handleChangeUser = (keyName, keyValue) => {
    setErrors({});
    let update = { ...user };
    update[keyName] = keyValue;
    setUserData(update);
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!user.contact_email.trim() || !isEmail(user.contact_email.trim())) {
      isValid = false;
      error["contact_email"] = "Please enter valid email address";
    }
    return {
      isValid,
      error
    };
  }

  const sendOtpHandle = (isResend) => {
    setErrors({});
    setUserData({contact_email: user?.contact_email.trim()});
    let validate = handleValidation();
    if (validate.isValid) {
      const agent = {
        contact_email: user?.contact_email.trim(),
      };
      if (agent) {
        dispatch(agentLogin(agent, isResend));
      }
    } else {
      setErrors(validate.error);
    }
  };

  const submitOtpHandle = () => {
    const body = {
      contact_email: user?.contact_email,
      local_otp:
        otpInput.input1 + otpInput.input2 + otpInput.input3 + otpInput.input4,
    };

    if (body) {
      dispatch(validateOtpForAgent(body));
    }
  };

  const handleClickRegister = () => {
    window.location = "/?register=true";
  };

  const onClickHandle = () => {
    dispatch(setOtpSent(false));
    setModalView("login-page")
  }

  return (
    <div className="admin-login">
      <h4>Login</h4>
      {modalView === "login-page" ? (
        <div>
          <Label mb="8px">Contact Email Id*</Label>
          <Input
            value={user.contact_email}
            onChange={(e) => handleChangeUser("contact_email", e.target.value)}
            border={user?.contact_email?.length ? "1px solid #606734" : null}
            placeholder="Enter Your Email ID"
            className="input-box"
            type="text"
            height="46px"
          />
          {errors.contact_email && <ErrorMessage>{errors.contact_email}</ErrorMessage>}
          {is_agent_found && (
            <ErrorMessage>
                This contact email is not registered with us.
            </ErrorMessage>
          )}
          <div className="d-flex justify-content-center mt-4">
            <Button
              bg={user.contact_email ? "#D0004B" : "#A0AEC0"}
              text="SEND OTP"
              disabled={user.contact_email ? "" : "true"}
              onClick={() => sendOtpHandle(false)}
            />
          </div>
        </div>
      ) : null}
      {/* otp-page */}
      {modalView === "otp-page" ? (
        <>
          <p className="d-flex justify-content-between">
            Your email id  <b>{truncate(user.contact_email, { length: 23 })}</b>
            <div
              onClick={() => onClickHandle()}
              style={{ cursor: "pointer" }}
            >
              <img src="https://ik.imagekit.io/rzlzf40okya/elixir/edit.svg" />
            </div>
          </p>
          <Label mb="8px">Enter OTP</Label>
          <div className="otp-bx">
            <input
              ref={firstInput}
              className={otp_error ? "otp-input otp-err" : "otp-input"}
              type="number"
              maxLength="1"
              value={otpInput.input1}
              onChange={(e) => {
                handleOnChange("input1", e.target.value)
                e.target.value && secondInput.current.focus();
              }}
            />
            <input
              ref={secondInput}
              className={otp_error ? "otp-input otp-err" : "otp-input"}
              type="number"
              maxLength="1"
              value={otpInput.input2}
              onChange={(e) => {
                handleOnChange("input2", e.target.value)
                !e.target.value
                  ? firstInput.current.focus()
                  : thirdInput.current.focus();
              }}
            />
            <input
              ref={thirdInput}
              className={otp_error ? "otp-input otp-err" : "otp-input"}
              type="number"
              maxLength="1"
              value={otpInput.input3}
              onChange={(e) => {
                handleOnChange("input3", e.target.value)
                !e.target.value
                  ? secondInput.current.focus()
                  : forthInput.current.focus();
              }}
            />
            <input
              ref={forthInput}
              className={otp_error ? "otp-input otp-err" : "otp-input"}
              type="number"
              maxLength="1"
              value={otpInput.input4}
              onChange={(e) => {
                handleOnChange("input4", e.target.value)
                !e.target.value && thirdInput.current.focus();
              }}
            />
          </div>
          <div className="TimerBody">
            <div>
              {minutes < 10 ? "0" + minutes : minutes}:
              {seconds < 10 ? "0" + seconds : seconds}
            </div>
            <div className="ResendLabel" onClick={() => sendOtpHandle(true)}>Resend OTP</div>
          </div>
          {
              otp_error &&
              <Box my="25px" color="#FE1717" textAlign="center">{otp_error}</Box>
          }
          <Button
            bg={
              otpInput.input1 &&
              otpInput.input2 &&
              otpInput.input3 &&
              otpInput.input4
                ? "#D0004B"
                : "#A0AEC0"
            }
            text="Login Now"
            disabled={
              !(
                otpInput.input1 &&
                otpInput.input2 &&
                otpInput.input3 &&
                otpInput.input4
              )
            }
            margin="auto"
            mt="18px"
            mb="18px"
            textColor="#fff"
            onClick={submitOtpHandle}
          />
        </>
      ) : null}
      <div>
        <a onClick={() => handleClickRegister()} style={{ cursor: "pointer" }}>
          Don’t have an account?{" "}
          <b style={{ color: "#D0004B" }}>Register with us</b>
        </a>
      </div>
    </div>
  );
};

export default MosqueLogin;
