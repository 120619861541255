import React, { useState } from "react";
import Box from "../../../../components/Box";
import Label from "../../../../components/Label";
import LoginTab from "./components/LoginTab";
import LoginBanner from "./components/LoginBanner";
import ResponseContainer from "../../../../components/SuccessPage/ResponseContainer";
import { useSelector } from "react-redux";

function LoginPage() {
  const [isSuccess, setIsSuccess] = useState(false);

  const isResetLinkSend = useSelector(
    (state) => state.adminReducer?.isResetLinkSend
  );

  return (
    <div>
      <LoginBanner />
      {!isResetLinkSend ? (
        <LoginTab />
      ) : (
        <ResponseContainer>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Label
              color="#1A1A1A"
              variant="text2"
              fontWeight={700}
              lineHeight="24px"
            >
              Password Reset Link Sent
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="16px"
            >
              We have sent password reset link to
            </Label>
            <Label
              color="#222222c2"
              variant="text5"
              fontWeight={600}
              lineHeight="24px"
              mt="4px"
            >
              shruti@gmail.com
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="12px"
            >
              Kindly open your gmail and click on the link
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="4px"
            >
              sent to you.
            </Label>
          </Box>
        </ResponseContainer>
      )}
    </div>
  );
}

export default LoginPage;
