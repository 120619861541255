import React, { useState } from "react";
import SelectBoxCountry from "./SelectBoxCountry";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingDetails } from "../../../customerAction";

function MosqueStepOne() {
  const dispatch = useDispatch();

  const onboardingDetails = useSelector((state) => state.customerReducer.onboardingDetails)

  const [errors, setError] = useState({});
  const [user, setUserData] = useState({
    entity_name: "",
    entity_address: "",
    entity_pincode: "",
    country: "",
  });

  const handleChangeUser = (keyName, keyValue) => {
    dispatch(setOnboardingDetails(keyName, keyValue))
    let update = { ...user };
    setError({});
    update[keyName] = keyValue;
    setUserData(update);
  };

  const handleValidation = () => {
    let isValid = true;
    let newErrors = {};
    if (!user.entity_name) {
      isValid = false;
      newErrors["entity_name"] = "Please enter entity_name";
    }
    if (!user.entity_address) {
      isValid = false;
      newErrors["entity_address"] = "please enter entity_address";
    }
    if (!user.entity_pincode) {
      isValid = false;
      newErrors["entity_pincode"] = "please enter entity_pincode";
    }
    return {
      isValid,
      errors: newErrors,
    };
  };

  const handleNext = () => {
    const check = handleValidation();
    if (check.isValid) {
      // let Updatesteps = {
      //     stepInfo: newstepInfo
      // }
      // setSegment("MosqueStepTwo");
      // window.location
    } else {
      setError(check.errors);
    }
  };

  return (
    <div>
      <div className="mosque-form">
        <h4>Enter Mosque Details</h4>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <label>
              Mosque Name<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails?.entity_name}
              onChange={(e) => handleChangeUser("entity_name", e.target.value)}
              type="text"
              placeholder="Enter Mosque Name"
              className="input-box"
            />
            {errors && errors.entity_name ? (
              <span className="error-msg">{errors.entity_name}</span>
            ) : null}
          </div>
          <div className="col-lg-6 mb-4">
            <label>
              Mosque Address<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails?.entity_address}
              onChange={(e) => handleChangeUser("entity_address", e.target.value)}
              type="text"
              placeholder="Enter Mosque Address"
              className="input-box"
            />
            {errors && errors.entity_address ? (
              <span className="error-msg">{errors.entity_address}</span>
            ) : null}
          </div>

          <div className="col-lg-6 mb-3">
            <label>
              Post Code<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails?.entity_pincode}
              onChange={(e) => handleChangeUser("entity_pincode", e.target.value)}
              placeholder="Enter Post Code"
              className="input-box"
            />
            {errors && errors.entity_pincode ? (
              <span className="error-msg">{errors.entity_pincode}</span>
            ) : null}
          </div>
          <div className="col-lg-6 mb-3">
            <label>
              Mosque Country<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <SelectBoxCountry />
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

export default MosqueStepOne
