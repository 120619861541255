import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Congratulation from "./Congratulation";
import SelectMosqueBoxPlan from "./SelectMosqueBoxPlan";
import { setOnboardingDetails } from "../../../customerAction";
import Label from "./../../../../../components/Label/Label";
import Icon from "./../../../../../components/Icon/index";
import Box from "./../../../../../components/Box/Box";

const MosqueStepThree = () => {
  const dispatch = useDispatch();

  const onboardingDetails = useSelector(
    (state) => state.customerReducer.onboardingDetails
  );
  const is_whatsapp_opted = useSelector(
    (state) => state.customerReducer.onboardingDetails?.is_whatsapp_opted
  );

  const { isAppliedTnC } = onboardingDetails;


  const is_onboarded = useSelector(
    (state) => state.customerReducer.is_onboarded
  );

  const handleChange = (keyName, keyValue) => {
    dispatch(setOnboardingDetails(keyName, keyValue));
  };

  return (
    <div style={{ margin: "30px 0px" }}>
      {!is_onboarded ? (
        <>
      <Label variant="text1">Your subscription includes</Label>
      <Box my="25px">
        <Box display="flex" alignItems="center">
          <Icon iconName="fourSquare" mr="10px" />
          <Box>
            <Label variant="text5" fontWeight={500}>
              Member management
            </Label>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" my="20px">
          <Icon iconName="fourSquare" mr="10px" />
          <Box>
            <Label variant="text5" fontWeight={500}>
              Recurring payments
            </Label>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Icon iconName="fourSquare" mr="10px" />
          <Box>
            <Label variant="text5" fontWeight={500}>
              Email & Whatsapp reminders for payments
            </Label>
          </Box>
        </Box>
      </Box>
          {onboardingDetails?.contact_phone && (
            <Box className="checkbox-container mt-4" ml="0px">
              I agree to receive WhatsApp notifications
              {is_whatsapp_opted && (
                <input type="checkbox" checked={is_whatsapp_opted} />
              )}
              <span
                className="checkmark"
                onClick={() => handleChange("is_whatsapp_opted", !is_whatsapp_opted)}
              />
            </Box>
          )}
          <Box className="checkbox-container mt-4" ml="0px">
            By clicking here you agree to our agree to the Terms & conditions
            and Privacy Policy
            {isAppliedTnC && <input type="checkbox" checked="checked" />}
            <span
              class="checkmark"
              onClick={() => handleChange("isAppliedTnC", !isAppliedTnC)}
            />
          </Box>
        </>
      ) : (
        <Congratulation />
      )}
    </div>
  );
};

export default MosqueStepThree;
