import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../../../components/Buttons";
import Input from "../../../../../components/Input";
import UploadFile from "../../../../../components/UplodeFile/UploadFile";
import { Helmet } from 'react-helmet';
import shortid from 'shortid';
import _ from 'lodash';
import {addNewBlog, fetchSingleBlog, updateBlog} from "../../../mosqueAction";
import { useParams, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import styled from "styled-components";
import moment from "moment";
import { getFormattedTime } from "../../../../../util/timeUtility";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Box from "../../../../../components/Box";

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }
  .ant-picker-focused {
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1A1A1A
  }
`;

function PublishBlog() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {blog_id} = useParams();
    const {blog_url} = useParams();

    const editBlog = useSelector((state) => state.mosqueReducer.edit_blog);
    const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);

    const [errorData, setErrors] = useState({});

    const [blogData, setBlogData] = useState({
        heading: "",
        text: "",
        preview_image: "",
        preview_image_alt: "",
        author_image: "",
        author_image_alt: "",
        author_name: "",
        tags: '',
        published_date: getFormattedTime(new Date(), "YYYY-MM-DD"),
    });

    useEffect(() => {
        if (editBlog && editBlog._id) {
            let newBlog = {...editBlog};
            if (_.isArray(newBlog.tags)) {
                newBlog.tags = editBlog?.tags?.join(',');
            }
            newBlog.published_date = moment(new Date(editBlog.published_date)).format("YYYY-MM-DD");
            setBlogData(newBlog)
        }
    }, [editBlog])

    useEffect(() => {
        if (blog_id && !editBlog?._id) {
            dispatch(fetchSingleBlog(blog_id));
        }
    }, [editBlog, blog_id])

    const handleChangeUser = (keyName, keyValue, value2) => {
        let update = {...blogData};
        if (keyName === "publishing_date") {
            update["publishing_date"] = new Date(keyValue?.$d).toISOString()
        }
        if (keyName === "preview_image") {
            update["preview_image"] = keyValue;
            update['preview_image_alt'] = value2
        }
        if (keyName === "author_image") {
            update["author_image"] = keyValue;
            update['author_image_alt'] = value2
        }
        update[keyName] = keyValue;
        setBlogData(update);
    };

    const validateBlog = (blogData) => {
        let isValid = true;
        let message = {};
        if (!blogData?.heading?.trim()) {
            isValid = false;
            message["heading"] = "Blog Heading Cannot Be Empty";
        }
        if (!blogData?.text?.trim()) {
            isValid = false;
            message["text"] = "Blog Body Cannot Be Empty";
        }
        if (!blogData?.tags?.trim()) {
            isValid = false;
            message["tags"] = "Blog Category Cannot Be Empty";
        }
        if (!blogData?.author_name?.trim()) {
            isValid = false;
            message["author_name"] = "Blog Author Name Cannot Be Empty";
        }
         if (!blogData?.preview_image?.trim()) {
             isValid = false;
             message["preview_image"] = "Blog Image Cannot Be Empty";
         }
        return {
            isValid,
            message
        }
    }

    const saveData = ()  => {
        let check = validateBlog(blogData);
        if (check.isValid) {
            if (editBlog && editBlog._id) {
                let newBlog = {...blogData};

                const trimmedDetails = {};
                // Trim each attribute and assign it to trimmedDetails object
                for (const key in newBlog) {
                    if (typeof newBlog[key] === "string") {
                        trimmedDetails[key] = newBlog[key].trim();
                    } else {
                        trimmedDetails[key] = newBlog[key];
                    }
                }

                trimmedDetails.tags = trimmedDetails.tags.split(",");
                dispatch(updateBlog(editBlog._id, trimmedDetails));
            } else {
                let newBlog = {...blogData};
                newBlog.entity_id = activeEntity._id;
                newBlog.organisation_id = activeEntity.organisation_id;

                const trimmedDetails = {};
                // Trim each attribute and assign it to trimmedDetails object
                for (const key in newBlog) {
                    if (typeof newBlog[key] === "string") {
                        trimmedDetails[key] = newBlog[key].trim();
                    } else {
                        trimmedDetails[key] = newBlog[key];
                    }
                }

                trimmedDetails.url = `${_.kebabCase(trimmedDetails.heading)}-${shortid.generate()}`;
                trimmedDetails.tags = trimmedDetails.tags.split(",");

                dispatch(addNewBlog(trimmedDetails));
            }
        } else {
            setErrors(check.message);
        }
    }

    let dataList = [
        {label: "Published Blogs", link: `/mosque/${activeEntity._id}/blogs`},
        {label: `${editBlog.heading}`, link: ""},
    ];

    const onOptionPreviewImage = (details, name) => {
        handleChangeUser('preview_image', details, name);
    }

    const onOptionAuthorImage = (details, name) => {
        handleChangeUser('author_image', details, name);
    }

    return (
        <div className="add_form form_width">
            <Helmet>
                <title>Publish Blog</title>
            </Helmet>
            <div className="mosque-form">
                {/* <h2>Add Member</h2> */}
                <div className="row">
                    <div className="col-lg-6">
                        <label>
                            Blog Title<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <Input
                            value={blogData?.heading}
                            onChange={(e) => handleChangeUser("heading", e.target.value)}
                            type="text"
                            placeholder="Enter blog title"
                            className="input-box"
                        />
                        {errorData.heading ? <ErrorMessage>{errorData.heading}</ErrorMessage> : null}
                    </div>
                    <div className="col-lg-6">
                        <label>
                            Category<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <Input
                            value={blogData.tags}
                            onChange={(e) =>
                                handleChangeUser("tags", e.target.value)
                            }
                            type="text"
                            placeholder="Enter Category"
                            className="input-box"
                        />
                        {errorData.tags ? <ErrorMessage>{errorData.tags}</ErrorMessage> : null}
                    </div>

                    <div className="col-lg-6 pt-4">
                        <label>
                            Author Name<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <Input
                            value={blogData.author_name}
                            onChange={(e) =>
                                handleChangeUser("author_name", e.target.value)
                            }
                            type="text"
                            placeholder="Address"
                            className="input-box"
                        />
                        {errorData.author_name ? <ErrorMessage>{errorData.author_name}</ErrorMessage> : null}
                    </div>
                    <div className="col-lg-6 pt-4">
                        <label>
                            Publishing Date<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <DatePickerBox>
                            <DatePicker defaultValue={dayjs()} value={dayjs(blogData?.published_date, 'YYYY-MM-DD')} onChange={(value) => handleChangeUser("published_date", value)} />
                        </DatePickerBox>
                        {errorData.published_date ? <ErrorMessage>{errorData.published_date}</ErrorMessage> : null}
                    </div>
                    <div className="col-lg-12 pt-3">
                        <label>
                            Blog Detail<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <textarea
                            value={blogData.text}
                            onChange={(e) =>
                                handleChangeUser("text", e.target.value)
                            }
                            //   placeholder="Enter Membership"
                            className="input-boxx"
                            style={{ padding: "10px" }}
                        />
                        {errorData.text ? <ErrorMessage>{errorData.text}</ErrorMessage> : null}
                    </div>
                    <div className="col-lg-6 mt-4">
                        <label>
                            Blog Image<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <UploadFile
                            id="preview"
                            onOptionClick={(data, name) => onOptionPreviewImage(data, name)}
                            fileName={blogData?.preview_image_alt}
                            bg="#fff"
                            height="66px"
                        />
                        {errorData.preview_image ? <ErrorMessage>{errorData.preview_image}</ErrorMessage> : null}
                    </div>
                    <div className="col-lg-6 mt-4">
                        <label>
                            Author Image
                        </label>
                        <UploadFile
                            id="author"
                            onOptionClick={(data, name) => onOptionAuthorImage(data, name)}
                            fileName={blogData?.author_image_alt}
                            bg="#fff"
                            height="66px"
                        />
                    </div>
                </div>
                <Box display="flex" justifyContent="center">
                    <Box mr="30px">
                        <Button
                            onClick={() => navigate(-1)}
                            text="Cancel"
                            borderRadius="66px"
                            textColor="#fff"
                            width="160px"
                            mt="5"
                            mx="auto"
                            height="60px"
                        />
                    </Box>
                    <Box>
                        <Button
                            onClick={() => saveData()}
                            text="Publish Blog"
                            borderRadius="66px"
                            textColor="#fff"
                            width="160px"
                            mt="5"
                            mx="auto"
                            height="60px"
                        />
                    </Box>
                </Box>
                <br/>
            </div>
        </div>
    );
}

export default PublishBlog;
