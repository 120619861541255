import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../css/addmember.css";
import Button from "../../../../../components/Buttons/Button";
import { Select } from "antd";
import { getRolesOption } from "../../../../../util/Utility";
import Icon from "../../../../../components/Icon/Icon";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import { updateAddedNewFlag, addNewAgent } from "../../../mosqueAction";
import Input from "../../../../../components/Input";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";
import styled from "styled-components";
import Box from "../../../../../components/Box";

const RoleBox = styled(Box)`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 55px;
  }
`;

const options = getRolesOption();

function AddNewAdmin() {
  const dispatch = useDispatch();

  const [isNext, setIsNext] = useState(false);

  const [errors, setErrors] = useState({});

  const [agentDetails, setAgentDetails] = useState({
    contact_name: "",
    contact_email: "",
    country_code: "91",
    contact_phone: "",
    role: ""
  });

  const isAddedNew = useSelector((state) => state.mosqueReducer.memberPlans?.isAddedNew);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);

  const formValidation = () => {
    return !!agentDetails?.contact_name.trim() &&
      !!agentDetails?.contact_email.trim() &&
      !!agentDetails?.country_code.trim() &&
      !!agentDetails?.contact_phone.trim() &&
      !!agentDetails?.role.trim();
  }

  useEffect(() => {
    if (agentDetails) {
      setIsNext(formValidation())
    }
  }, [agentDetails])

  const handleAgentDetails = (keyName, keyValue) => {
    setErrors({});
    const details = {...agentDetails};
    details[keyName] = keyValue;
    setAgentDetails(details);
  };

  const handleAdd = () => {
    const details = {...agentDetails};
    details.organisation_id = activeEntity?.organisation_id;

    const trimmedDetails = {};
    // Trim each attribute and assign it to trimmedDetails object
    for (const key in details) {
      if (typeof details[key] === "string") {
        trimmedDetails[key] = details[key].trim();
      } else {
        trimmedDetails[key] = details[key];
      }
    }

    dispatch(addNewAgent(trimmedDetails));
  }

  const handleAddNew = () => {
    dispatch(updateAddedNewFlag(false));
    window.location.href = `/mosque/${activeEntity?._id}/subscriptions?new`
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!agentDetails.contact_email || !isEmail(agentDetails.contact_email)) {
      isValid = false;
      error["contact_email"] = "Please enter valid email address";
    }
    return {
      isValid,
      error
    };
  }

  const handleEmailValidation = () => {
    setErrors({});
    let validate = handleValidation();
    if (!validate.isValid) {
      setErrors(validate.error);
    }
  }

  return (
    <div className="add_form">
      <div className="mosque-form">
        <div className="row">
          <div className="col-lg-6">
            <label>
              Full Name<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={agentDetails?.contact_name}
              onChange={(e) => handleAgentDetails("contact_name", e.target.value)}
              type="text"
              placeholder="Enter Full Name"
              className="input-box"
            />
          </div>
          <div className="col-lg-6 ">
            <label>
              Email Address<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input onBlur={() => handleEmailValidation()}
              value={agentDetails?.contact_email}
              onChange={(e) => handleAgentDetails("contact_email", e.target.value)}
              type="text"
              placeholder="Enter Email Address"
              className="input-box"
            />
            {errors.contact_email && <ErrorMessage>{errors.contact_email}</ErrorMessage>}
          </div>

          <div className="col-lg-6 pt-5">
            <label>
              Contact Number<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
                value={agentDetails.contact_phone}
                countryCodes={true}
                setCountryCode={(e) => handleAgentDetails("country_code", e)}
                onChange={(e) =>
                    handleAgentDetails("contact_phone", e.target.value)
                }
                border={agentDetails.contact_phone.length ? "1px solid #606734" : null}
                placeholder="785-785-4644"
                className="input-box"
                type="number"
                pattern="[0-9]"
            />
          </div>
          <div className="col-lg-6 pt-5">
            <label>
              Role<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <RoleBox>
              <Select
                  size={"middle"}
                  value={agentDetails?.role || "Select role"}
                  defaultValue="Select role"
                  onChange={(value) => handleAgentDetails("role", value)}
                  style={{ width: "100%" }}
                  options={options}
              />
            </RoleBox>
          </div>
        </div>
        <Button
          type={!isNext ? "disabled" : "primary"}
          text="Save Details"
          width="15%"
          mt="4"
          mx="auto"
          onClick={() => handleAdd()}
        />
        <br />
        <DefaultModal
          show={isAddedNew}
          onHide={() => dispatch(updateAddedNewFlag(false))}
          className="add-mosque-modal"
          centered={true}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <div>
            <Icon
              iconName="modalClose"
              iconSize="xs"
              style={{ marginLeft: "auto" }}
              onClick={() => dispatch(updateAddedNewFlag(false))}
            />
            <div className="text-center add-mosque-modal-content">
              <Icon
                iconName="congratulations"
                style={{ justifyContent: "center" }}
              />
              <div>Congratulations!</div>
              <div>
                We have added the new plan. You can make edits to <br/>
                existing plans anytime.
              </div>
              <button onClick={() => handleAddNew()}>Add New plan </button>
            </div>
          </div>
        </DefaultModal>
      </div>
    </div>
  );
}

export default AddNewAdmin;
