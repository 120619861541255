import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/Input/Input";
import { setOnboardingDetails } from "../../../customerAction";
import { Select } from "antd";
import { getPaymentMethodOption } from "../../../../../util/Utility";
import ErrorMessage from "../../../../../components/ErrorMessage";
import isEmail from "validator/lib/isEmail";

const options = getPaymentMethodOption();

function MosqueStepTwo() {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});

  const onboardingDetails = useSelector((state) => state.customerReducer.onboardingDetails);

  const [user, setUserData] = useState({
    contact_name: "",
    contact_email: "",
    code: "",
    contact_phone: "",
    country_code: onboardingDetails?.country_code || "+31",
  });

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!onboardingDetails.contact_email || !isEmail(onboardingDetails.contact_email)) {
      isValid = false;
      error["contact_email"] = "Please enter valid email address";
    }
    return {
      isValid,
      error
    };
  }

  const handleChangeUser = (keyName, keyValue) => {
    setErrors({});
    dispatch(setOnboardingDetails(keyName, keyValue));
    let update = { ...user };
    update[keyName] = keyValue;
    setUserData(update);
  };

  const handleEmailValidation = () => {
    setErrors({});
    let validate = handleValidation();
    if (!validate.isValid) {
      setErrors(validate.error);
    }
  }

  return (
    <div>
      <div className="mosque-form">
        <h4>Enter Admin Details</h4>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <label>
              Full Name<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails.contact_name}
              onChange={(e) => handleChangeUser("contact_name", e.target.value)}
              type="text"
              placeholder="contact_name"
              className="input-box"
            />
          </div>
          <div className="col-lg-6 mb-3">
            <label>
              Email Address<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              onBlur={() => handleEmailValidation()}
              value={onboardingDetails.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder="Email Address"
              className="input-box"
            />
            {errors.contact_email && <ErrorMessage>{errors.contact_email}</ErrorMessage>}
          </div>
          <div className="col-lg-6 mb-3">
            <label>
              Contact Number
            </label>
            <Input
              value={onboardingDetails.contact_phone}
              countryCodes={true}
              countryCodeValue={onboardingDetails?.country_code}
              setCountryCode={(e) => handleChangeUser("country_code", e)}
              onChange={(e) =>
                handleChangeUser("contact_phone", e.target.value)
              }
              placeholder="785-785-4644"
              className="input-box"
              type="number"
              pattern="[0-9]"
              height="52px"
              overflow="unset"
            />
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

export default MosqueStepTwo;
