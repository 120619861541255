import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import MosqueStepOne from "./MosqueStepOne";
import MosqueStepTwo from "./MosqueStepTwo";
import MosqueStepThree from "./MosqueStepThree";
import {newMosqueOnboard} from "../../../customerAction";
import isEmail from "validator/lib/isEmail";
import { showNotification } from "../../../../../util/Utility";

const StepperContainer = styled.div`
  border-bottom: 1px solid #d9dbe9;
`;

const StepperContainerUi = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  background: #fff;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Status = styled.div`
  width: 35px;
  height: 35px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.active ? "#D0004B" : "#EFF0F6")};
  color: ${(props) => (props.active ? "#FFFFFF" : "#7E7E7E")};
  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
`;

const Separator = styled.div`
  height: 6px;
  width: 30%;
  background: ${(props) => (props.active ? "#D0004B" : "#EFF0F6")};
  border-radius: 40px;
  margin: 0 10px;
  @media (max-width: 768px) {
    height: 3px;
  }
`;

function MosqueDetails({defaultStep = 0}) {
    const dispatch = useDispatch();
    const [current, setCurrent] = useState(0);
    const [isNext, setIsNext] = useState(false);
    const [isStepOne, setIsStepOne] = useState(false);
    const [isStepTwo, setIsStepTwo] = useState(false);
    const [isStepThree, setIsStepThree] = useState(false);

    useEffect(() => {
        if (defaultStep) {
            setCurrent(defaultStep);
        }
    }, [defaultStep])

    const onboardingDetails = useSelector(
        (state) => state.customerReducer.onboardingDetails
    );
    const is_onboarded = useSelector(
        (state) => state.customerReducer.is_onboarded
    );
    const is_onboarding = useSelector(
        (state) => state.customerReducer.is_onboarding
    );

    const stepOneValidation = () => {
        return (
            !!onboardingDetails?.entity_name.trim() &&
            !!onboardingDetails?.entity_address.trim() &&
            !!onboardingDetails?.entity_pincode.trim() &&
            !!onboardingDetails?.entity_country.trim()
        );
    };

    const stepTwoValidation = () => {
        return (
            !!onboardingDetails?.contact_name.trim() &&
            !!onboardingDetails?.contact_email.trim() &&
            !!isEmail(onboardingDetails?.contact_email.trim())
        );
    };

    const stepThreeValidation = () => {
        return true;
    };

    useEffect(() => {
        if (is_onboarded) {
            setIsStepThree(true);
        }
    }, [is_onboarded]);

    useEffect(() => {
        if (current === 0) {
            setIsNext(stepOneValidation());
        }
        if (current === 1) {
            setIsNext(stepTwoValidation());
        }
        if (current === 2) {
            setIsNext(stepThreeValidation());
        }
    }, [current, onboardingDetails]);

    const renderComponent = () => {
        switch (current) {
            case 0:
                return <MosqueStepOne/>;

            case 1:
                return <MosqueStepTwo/>;

            case 2:
                return <MosqueStepThree/>;

            default:
                return null;
        }
    };

    const handleSubmit = () => {
      if (!!onboardingDetails?.contact_phone.trim()) {
        if (!onboardingDetails?.is_whatsapp_opted) {
            showNotification("warning", "Please agree to Email & Whatsapp reminders for payments to proceed further.");
        } else if (!onboardingDetails?.isAppliedTnC) {
            showNotification("warning", "Please agree to terms and conditions to proceed further.");
        } else {
            dispatch(newMosqueOnboard());
        }
      } else if (!onboardingDetails?.isAppliedTnC) {
        showNotification("warning", "Please agree to terms and conditions to proceed further.")
      } else {
          dispatch(newMosqueOnboard());
      }
    };

    const next = (step) => {
        setCurrent(current + 1);
        setIsNext(false);
        if (step === 0) {
            setIsStepOne(true);
        }
        if (step === 1) {
            setIsStepTwo(true);
        }
        if (step === 2) {
            setIsStepThree(true);
        }
    };

    const prev = (step) => {
        setCurrent(current - 1);
        if (step === 1) {
            setIsStepOne(false);
            setIsStepTwo(false);
            setIsStepThree(false);
        }
        if (step === 2) {
            setIsStepOne(true);
            setIsStepTwo(false);
            setIsStepThree(false);
        }
    };

    return (
        <div>
            <StepperContainer>
                {/*<OnboardingSteps />*/}

                <StepperContainerUi>
                    <Step>
                        <Status active={current === 0 || isStepOne}>1</Status>
                    </Step>
                    <Separator active={isStepOne}/>
                    <Step>
                        <Status active={current === 1 || isStepTwo}>2</Status>
                    </Step>
                    <Separator active={isStepTwo}/>
                    <Step>
                        <Status active={current === 2}>3</Status>
                    </Step>
                </StepperContainerUi>
            </StepperContainer>

            <div>{renderComponent()}</div>

            <div className="d-flex justify-content-between flex-row-reverse align-items-baseline">
                {current < 2 && (
                    <div
                        className="d-flex justify-content-end"
                        style={{width: "50%"}}
                    >
                        {isNext ? (
                            <button className="fill-bt" onClick={() => next(current)}>
                                Next Step
                            </button>
                        ) : (
                            <button className="gray-bt" disabled>
                                Next Step
                            </button>
                        )}
                    </div>
                )}
                <div
                    className="d-flex justify-content-between"
                    style={
                        !is_onboarded && current === 2
                            ? {width: "100%"}
                            : {width: "50%"}
                    }
                >
                    {!is_onboarded && current > 0 && (
                        <button
                            className="prev-bt"
                            style={{margin: "0 8px"}}
                            onClick={() => prev(current)}
                        >
                            Previous Step
                        </button>
                    )}
                    {!is_onboarded &&
                        current === 2 &&
                        (isNext ? (
                            <button className="fill-bt" onClick={() => handleSubmit()}>
                                {is_onboarding ? "Submitting.." : "Submit"}
                            </button>
                        ) : (
                            <button className="gray-bt" disabled>
                                Submit
                            </button>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default MosqueDetails;
