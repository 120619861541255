import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "../../../components/Box";
import Icon from "../../../components/Icon/Icon";
import useOutsideClick from "../../../customHooks/useOutsideClick";
import Searchbar from "../../../components/Searchbar";
import DefaultModal from "../../../components/DefaultModal/DefaultModal";
import Button from "../../../components/Buttons";
import { getFormattedRelativeTime } from "../../../util/timeUtility";
import { entityApprove } from "../adminAction";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const setInitialValue = () => {
    setIsShown(false);
  };

  const user = useSelector((state) => state.adminReducer.admin);
  const approvalList = useSelector((state) => state.adminReducer.approvalList);
  const approvalListCount = useSelector(
    (state) => state.adminReducer.approvalListCount
  );

  const ref = useOutsideClick(() => setInitialValue());

  const handleClickProfile = () => {
    window.location.href = "/admin/profile";
  };

  const handleApproval = (entityId, approvalId) => {
    navigate(`/admin/mosque/add?entityId=${entityId}&approvalId=${approvalId}`);
    setShow(false);
    //if (entityId && approvalId) {
    //  dispatch(entityApprove(entityId, approvalId));
    //}
  };

  return (
    <div ref={ref}>
      <div className="header_sec">
        <div className={"header-flex"}>
          <h1>Hello {user?.contact_name} 👋🏼,</h1>
          <div className="d-flex align-items-center">
            {/* <Searchbar
            value={value}
            setValue={(e) => setValue(e.target.value)}
            placeholder="Search"
            fontSize="12px"
            background={"#F5F5F5"}
            borderRadius="12px"
            searchIcon
            backgroundColor="#F5F6FA"
            width="295px"
            height="40px"
            position="relative"
            padding="0px 20px"
          /> */}
            <div>
              <div className="noti-msg" onClick={() => setShow(true)}>
                {!show && (
                  <span className="notification">{approvalListCount}</span>
                )}
                {!show ? <Icon iconName="BellIcon" /> : <Box width="21px" />}
              </div>
            </div>
            <div
              className="admin-profile-links"
              style={{ cursor: "pointer" }}
              onClick={() => handleClickProfile()}
            >
              <div className="profile-im">
                {user?.profile_pic ? (
                    <img
                        src={user?.profile_pic}
                        width="45px"
                        height="45px"
                        style={{ borderRadius: "50%" }}
                    />
                ) : (
                    <Box
                        width="40px"
                        height="40px"
                        borderRadius="50%"
                        bg="#D0004B"
                    >
                      <div className={"text-center"} style={{ paddingTop: "8px", color: "white" }}>{user && user.contact_name ? user?.contact_name[0] : ""}</div>
                    </Box>
                )}
              </div>
              {/*<div className="profile-im" style={{ right: "15px" }}>*/}
              {/*  <img src="https://ik.imagekit.io/rzlzf40okya/elixir/profile_im.svg" />*/}
              {/*</div>*/}
              {isShown && (
                <ul className="admin-profile-links-ul">
                  <li>
                    <a href="/admin/profile">My Profile</a>
                  </li>
                  <li>Add Admin</li>
                  <li>Existing Admin</li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="notification-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="noti-msg-white" onClick={() => setShow(true)}>
          <span className="notification">{approvalListCount}</span>
          <Icon iconName="BellIconWhite" />
        </div>
        <div className="noti-popup">
          <div className="d-flex justify-content-between noti-heading">
            <h5>Notifications</h5>
            {/*<h6>Mark As Read</h6>*/}
          </div>
          <div className="modal-scroll">
            {approvalList &&
              approvalList.map((item) => (
                <>
                  <div className="border-bottom mb-2"></div>
                  <div className="msg-notification">
                    <span className="dot"></span>
                    <div className="bblue">{"#"}</div>
                    <div className="ml-4">
                      <p>
                        <b>{item?.task_name} </b>
                      </p>
                      <small>
                        {getFormattedRelativeTime(item?.created_at)}
                      </small>

                      <p>
                        <Box my="10px">
                          <Box>
                            <Button
                              type="outlineColor"
                              minHeight="35px"
                              text="Review"
                              onClick={() =>
                                handleApproval(item?.ref_id, item?._id)
                              }
                            />
                          </Box>
                        </Box>
                      </p>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default Header;
