import React from "react";

function LoginBanner() {
    const handleLogoClick = () => {
        window.location.href = "/";
    }
    return (
        <div className="login_banner">
            <div className="logo_im_mobile">
            {/*<div className="logo-div" onClick={() => handleLogoClick()}>*/}
                <img style={{ height: "100%", width: "100px" }} src="./assets/Logo.png"/>
            </div>
            <div className="container">
                <div className="logo_im mobile-view" onClick={() => handleLogoClick()}>
                    {/*<div className="logo-div" onClick={() => handleLogoClick()}>*/}
                        <img style={{ height: "100%", width: "100px" }} src="assets/Logo.png"/>
                    </div>
                {/*</div>*/}
                <div className="login_content">
                    <h3>Welcome!</h3>
                    <p>
                        Login or Create A New Account
                    </p>
                    <br/>
                </div>
                <br/>
            </div>
        </div>
    );
}

export default LoginBanner;
