import React, {useEffect, useState} from 'react'
import moment from "moment";
import styled from "styled-components";
import {useSelector} from "react-redux";
import Label from "../../../../../components/Label";
import Box from "../../../../../components/Box";
// import CurveLineChart from "../../../../../components/D3Charts/CurveLineChart";
import LineChart from "../../../../../components/D3Charts/LineChart";

const FinancialBoxShadow = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
  0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 20px;
`;

const RevenueCard = () => {
    const [details, setDetails] = useState({});
    const [pending_details, setPendingDetails] = useState({});

    const memberRevenueStats = useSelector(state => state.mosqueReducer.memberRevenueStats);

    useEffect(() => {
        if (memberRevenueStats?.length > 0) {
            const result = {};
            const pending_result = {};
            memberRevenueStats.forEach(data => {
                const {year, month} = data._id;
                const {total_paid_amount, total_pending_amount} = data
                if (!result[year]) {
                    result[year] = Array(12).fill(0); // Initialize the array with zeros for all months
                    pending_result[year] = Array(12).fill(0); // Initialize the array with zeros for all months
                    setDetails(result);
                    setPendingDetails(pending_result);
                }
                result[year][month - 1] = total_paid_amount; // Update the amount for the corresponding month
                pending_result[year][month - 1] = total_pending_amount; // Update the amount for the corresponding month
                setDetails(result);
                setPendingDetails(pending_result);
            });
        } else {
            const result = {};
            const pending_result = {};
            result[moment().year()] = Array(12).fill(0); // Initialize the array with zeros for all months
            pending_result[moment().year()] = Array(12).fill(0); // Initialize the array with zeros for all months
            setDetails(result);
            setPendingDetails(pending_result);
        }
    }, [memberRevenueStats]);

    // const getCommulativeResults = (array) => {
    //     let new_array = [];
    //     array.map((item, index) => {
    //         console.log(item);
    //         new_array[index] = index > 0 ? new_array[index] + item  : item;
    //     });
    //     console.log(new_array, "new_Array");
    //     return new_array;
    // };

    const transformedResult = Object.entries(details).map(([year, data]) => ({
        name: year.toString(),
        data: data
    }));
    const pendingTransformedResult = Object.entries(pending_details).map(([year, data]) => ({
        name: year.toString(),
        data: data
    }));

    console.log("details", details, pending_details, transformedResult, pendingTransformedResult);
    if (details && pending_details) {
        return (
            <Box mt="44px">
                <FinancialBoxShadow>
                    <Label fontSize="24px" fontWeight="700" justifyContent="center">
                        Total Revenue
                    </Label>
                    <LineChart
                        dataSet={[transformedResult ? transformedResult[0] : [], pendingTransformedResult ?  pendingTransformedResult[0] : []]}
                        colors={["#80CBC4", "#D0004B"]}
                        categories={[
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                        ]}
                    />
                </FinancialBoxShadow>
            </Box>
        )
    }
    return null;
}

export default RevenueCard
