import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Banner from "./components/Banner";
import Box from "../../../../components/Box";
import Label from "../../../../components/Label";
import BodyContainer from "./components/BodyContainer";
import ResponseContainer from "../../../../components/SuccessPage/ResponseContainer";
import Button from "../../../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { PASSWORD_CHANGE_SUCCESS } from "../../adminActionType";

const GeneralLink = styled(Link)`
  text-decoration: unset;
`;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPage = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const resetToken = query.get("reset_token");
  const resetEmail = query.get("email");

  useEffect(() => {
    return () => {
      dispatch({
        type: PASSWORD_CHANGE_SUCCESS,
        isFetching: false,
        isPasswordChange: false,
      });
    };
  }, []);

  const isPasswordChange = useSelector(
    (state) => state.adminReducer?.isPasswordChange
  );

  return (
    <div>
      <Banner link={"/admin/login"} />
      {!isPasswordChange ? (
        <BodyContainer resetToken={resetToken} resetEmail={resetEmail} />
      ) : (
        <ResponseContainer>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Label
              color="#1A1A1A"
              variant="text2"
              fontWeight={700}
              lineHeight="24px"
            >
              Password Changed
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="16px"
            >
              Lorem Ipsum is simply dummy text of the
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="4px"
            >
              printing and typesetting industry.
            </Label>

            <Box display="flex" justifyContent="center" mt="54px">
              <GeneralLink to="/admin/login">
                <Button width="40px">Login Now</Button>
              </GeneralLink>
            </Box>
          </Box>
        </ResponseContainer>
      )}
    </div>
  );
};

export default ResetPage;
