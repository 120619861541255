import React, {useEffect, useState} from "react";
import Icon from "../../../../../components/Icon/Icon";
import Button from "../../../../../components/Buttons/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchMemberDetails,
    fetchMemberPaymentHistory,
    fetchMemberReminderHistory,
    sendMemberReminders,
    setCustomerUpdateStatus,
    updateCustomerInfo,
    updateMemberSubscriptionInfo
} from "../../../mosqueAction";
import dayjs from "dayjs";
import {MonthData} from "../../../../../constants/AdminData";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Input from "../../../../../components/Input";
import ErrorMessage from "../../../../../components/ErrorMessage";
import {getFormattedTime} from "../../../../../util/timeUtility";
import styled from "styled-components";
import {isMonthUpcoming, showNotification} from "../../../../../util/Utility";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import DisableMemberModal from "./DisableMemberModal";
import truncate from "lodash/truncate";
import moment from 'moment';

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker-focused {
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1A1A1A
  }
`;

const ProfileModal = ({onHide}) => {
    const dispatch = useDispatch();

    const [isActiveEdit, setIsActiveEdit] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [activeMemberDetails, setActiveMemberDetails] = useState(null);
    const [errors, setErrors] = useState({});
    const [historyPageNum, setHistoryPageNum] = useState(1);
    const [historyPageSize, setHistoryPageSize] = useState(2);

    const activeEntity = useSelector(state => state.mosqueReducer.activeEntity);
    const activeMember = useSelector(state => state.mosqueReducer.activeMember);
    const customer = useSelector(state => state.mosqueReducer.memberDetails?.customer);
    const isUpdatedSuccessfully = useSelector(state => state.mosqueReducer.memberDetails?.isUpdatedSuccessfully);
    const customerInfoPayments = useSelector((state) => state.mosqueReducer.customerInfo?.customerInfoPayments);
    const reminder_history = useSelector(state => state.mosqueReducer.memberDetails?.reminder_history);
    const payment_history = useSelector(state => state.mosqueReducer.memberDetails?.payment_history);
    const total_reminder_count = useSelector(state => state.mosqueReducer.memberDetails?.total_reminder_count);
    const is_sending_reminder = useSelector((state) => state.mosqueReducer.is_sending_reminder);
    const memberPlans = useSelector((state) => state.mosqueReducer.memberPlans);
    const plan_list = memberPlans?.planList;

    const showLoadMoreButton = Math.ceil(total_reminder_count / historyPageSize);

    useEffect(() => {
        if (customer) {
            setCustomerDetails(customer);
        }
        if (activeMember) {
            setActiveMemberDetails(activeMember);
        }
    }, [customer, activeMember])

    useEffect(() => {
        if (activeMember && activeMember._id) {
            dispatch(fetchMemberDetails(activeMember._id));
            dispatch(fetchMemberPaymentHistory({member_id: activeMember.member_id, pageNum: 1, pageSize: 50}));
        }
    }, [activeMember, isActiveEdit]);

    useEffect(() => {
        if (activeMember && activeMember._id) {
            dispatch(fetchMemberReminderHistory({
                member_id: activeMember.member_id,
                pageNum: historyPageNum,
                pageSize: historyPageSize
            }));
        }
    }, [])

    const loadMoreButton = (page) => {
        setHistoryPageNum(page);
        if (page && historyPageSize) {
            dispatch(fetchMemberReminderHistory({
                member_id: activeMember.member_id,
                pageNum: page,
                pageSize: historyPageSize
            }));
        }
    }

    const optionsPlan = () => {
        let listValues = [];
        if (plan_list?.length) {
            const list = plan_list?.forEach((reply, index) => {
                listValues.push({
                    ...reply,
                    label: reply?.plan_name,
                    value: reply?.plan_name,
                });
            });
        }
        return listValues;
    };

    const renderPaymentStatus = (item, month) => {
        const filteredData = customerInfoPayments[item?.member_id]?.filter(
            (payment) => {
                return payment.payment_amount > 0 && dayjs(new Date(payment?.payment_due_date)).format("MMM").toLowerCase() === month
            }
        );
        if (isMonthUpcoming(month)) {
            return null;
        } else if (filteredData?.length > 0) {
            return (
                <Box
                    position="relative"
                    top="8px"
                    left="4px"
                    width="10px"
                    height="10px"
                    className={
                        filteredData?.[0]?.payment_status !== "paid"
                            ? "red-color"
                            : "green-color"
                    }
                ></Box>
            );
        } else {
            return <Box ml="7px">{"-"}</Box>;
        }
    };

    const disableMember = () => {
        if (activeMemberDetails?.is_active) {
            setIsDisable(!isDisable);
        } else {
            updateStatus();
        }
    }

    const updateStatus = () => {
        const update = {}
        if (activeMemberDetails?.is_active) {
            update.is_active = false;
            dispatch(updateMemberSubscriptionInfo(activeMemberDetails?._id, update, true));
        } else {
            update.is_active = true;
            dispatch(updateMemberSubscriptionInfo(activeMemberDetails?._id, update, true));
        }
    }

    const handleUpdateDetails = (keyName, keyValue) => {
        setErrors({});
        setIsNext(true);
        const data = {...customerDetails};
        if (keyName === "address") {
            data["address"] = keyValue;
        } else {
            data[keyName] = keyValue;
        }
        setCustomerDetails(data);
    }

    const handleChange = (keyName, keyValue) => {
        setErrors({});
        setIsNext(true);
        const data = {...activeMemberDetails};
        data[keyName] = keyValue;
        setActiveMemberDetails(data);
    }

    const handleValidation = () => {
        let isValid = true;
        let error = {};
        if (!activeMemberDetails.membership.trim()) {
            isValid = false;
            error["membership"] = "Please enter membership";
        }
        if (!customerDetails.address.trim()) {
            isValid = false;
            error["address"] = "Please enter address";
        }
        return {
            isValid,
            error
        };
    }

    const handleSubmit = () => {
        setErrors({});
        let validate = handleValidation();
        activeMemberDetails["membership"] = activeMemberDetails["membership"].trim();
        customerDetails["address"] = customerDetails["address"].trim();
        if (validate.isValid) {
            setIsActiveEdit(false);
            if (activeMemberDetails) {
                dispatch(updateMemberSubscriptionInfo(activeMemberDetails?._id, activeMemberDetails));
            }
            if (customerDetails) {
                dispatch(updateCustomerInfo(customerDetails?._id, customerDetails));
            }
        } else {
            setErrors(validate.error);
        }
    }

    const handleCancel = () => {
        setErrors({});
        setIsActiveEdit(!isActiveEdit);
    }

    const sendReminder = async () => {
        let members = [];
        members.push(activeMember?._id);
        if (members.length > 0) {
            dispatch(sendMemberReminders({
                entity_id: activeEntity?._id,
                activeMember: activeMember,
                members,
                isProfileModal: true,
                pageNum: historyPageNum,
                pageSize: historyPageSize
            }))
        } else {
            showNotification("error", "Please Select Users");
        }
    };

    const getIfCurrentPaymentIsPending = (payment_history) => {
        if (payment_history && payment_history.length > 0) {
            let start_of_month = moment().startOf('month');
            let end_of_month = moment().endOf('month');
            let filtered_data = payment_history.filter(item => item.payment_status !== "paid" && moment(new Date(item.payment_due_date)).isBetween(start_of_month, end_of_month));
            if (filtered_data && filtered_data.length) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    console.log("customerInfoPayments", customerInfoPayments);

    return (
        <Box>
            <Box style={{margin: "0px 20px"}}>
                <div className="d-flex justify-content-between">
                    <div>

                    </div>
                    <div style={{cursor: "pointer"}} onClick={() => onHide(null, false)}>
                        <Icon
                            style={{zIndex: 2000}}
                            iconName="cross"
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-baseline">
                        {/* <h4>{customerDetails?.contact_name}</h4> */}
                        <h4 style={{cursor: "pointer"}}
                            title={customerDetails?.contact_name || 'NA'}
                            onMouseOver={(e) => (e.target.title = customerDetails?.contact_name)}
                            onMouseOut={(e) => (e.target.title = customerDetails?.contact_name ? truncate(customerDetails?.contact_name, {length: 20}) : 'NA')}
                        >{customerDetails?.contact_name ? truncate(customerDetails?.contact_name, {length: 20}) : 'NA'}</h4>
                        <div>
                            <label className="switch">
                                <input type="checkbox" onChange={() => disableMember()}
                                       checked={!!activeMemberDetails?.is_active}/>
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="payment-small-details">
                    {activeMemberDetails?.recurring_type &&
                        <small className="monthly">{activeMemberDetails?.recurring_type}</small>}
                    {activeMemberDetails?.payment_method &&
                        <small className="autoPay">{activeMemberDetails?.payment_method}</small>}
                </div>
            </Box>
            <Box className='profile-scroll member-deatil-button'>
                <Box className="details-card">
                    <div className="d-flex">
                        <Icon
                            iconName="user"
                        />
                        <h5>Personal Details</h5>
                    </div>
                    <Box mb="15px" className="d-flex justify-content-between member_ship">
                        <Box>

                            <Label color="#7E7E7E" mb="8px" fontSize="14px">Membership</Label>
                            <Box style={{fontWeight: "600", fontSize: "16px"}}>

                                {activeMemberDetails?.membership || "-"}
                            </Box>
                        </Box>
                        <Box mr="20px">

                            <Label color="#7E7E7E" mb="8px" fontSize="14px">Membership Since</Label>
                            <Box style={{fontWeight: "600", fontSize: "16px"}}>

                                {getFormattedTime(activeMemberDetails?.member_since, "DD-MM-YYYY") || "-"}
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <Label color="#7E7E7E" mb="8px" fontSize="14px"> Address</Label>
                        {
                            isActiveEdit ?
                                <>
                                    <Input
                                        value={customerDetails?.address}
                                        className="input-box"
                                        onChange={(e) => handleUpdateDetails("address", e.target.value)}

                                    />
                                    {errors.address && <ErrorMessage>{errors.address}</ErrorMessage>}
                                </>
                                :

                                <Box style={{fontWeight: "600", fontSize: "16px"}}>

                                    {customerDetails?.address || "-"}
                                </Box>
                        }
                    </Box>
                    <Box className="d-flex justify-content-between">
                        <div>

                            <Label color="#7E7E7E" mb="8px" fontSize="14px">Email</Label>
                            <Input
                                value={customerDetails?.contact_email}
                                className="input-box"
                                onChange={(e) => handleUpdateDetails("contact_email", e.target.value)}
                            />
                            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                        </div>
                        <div>
                            <Label color="#7E7E7E" mb="8px" fontSize="14px">Contact Number</Label>
                            <Input
                                value={customerDetails?.contact_phone}
                                countryCodes={true}
                                countryCodeValue={customerDetails?.country_code}
                                setCountryCode={(e) => handleUpdateDetails("country_code", e)}
                                onChange={(e) =>
                                    handleUpdateDetails("contact_phone", e.target.value)
                                }
                                border={customerDetails?.contact_phone?.length ? "1px solid #606734" : null}
                                placeholder="785-785-4644"
                                className="input-box"
                                type="number"
                                pattern="[0-9]"
                                overflow="unset"

                            />
                            {errors.contact_phone && (
                                <ErrorMessage>{errors.contact_phone}</ErrorMessage>
                            )}
                        </div>
                    </Box>
                </Box>
                <div className="details-card">
                    <div className="d-flex align-items-baseline">
                        <Icon
                            iconName="payment"
                            mr="10px"
                        />
                        <h5>PAYMENT HISTORY</h5>
                    </div>
                    <div className="d-flex justify-content-between member_ship">
                        <div>
                            <label style={{fontSize: "14px"}}>Total Payments Received</label>
                            <h6><b>{activeMemberDetails?.total_payments_done}</b></h6>
                        </div>
                        <div>
                            <label style={{fontSize: "14px"}}>Pending Payments</label>
                            <h6><b>{activeMemberDetails?.total_pending_payments}</b></h6>

                        </div>
                    </div>
                    <div>
                        <label className="label-missed-payments" style={{fontSize: "14px"}}>Past Missed Payments</label>
                        <h6><b>0</b></h6>

                    </div>
                    <br/>
                    <div className="month-day">
                        {MonthData &&
                            MonthData.map((item, i) => (
                                <div>
                                    <h6>{item.name}</h6>
                                    {renderPaymentStatus(activeMemberDetails, item?.name?.toLowerCase())}
                                </div>
                            ))}
                    </div>
                </div>
                <div className="details-card">
                    <div className="d-flex">
                        <Icon
                            iconName="historyMsg"
                            mr="10px"
                        />
                        <h5>REMINDER HISTORY</h5>
                    </div>
                    <div className="d-flex">
                        <div className="sent-msg label-sent-msg mb-2">
                            <label style={{fontSize: "13px", minWidth: "130px"}}>Sent On</label>
                        </div>
                        <div className="message label-message mb-2">
                            <label style={{fontSize: "13px"}}>Message</label>
                        </div>
                    </div>
                    {reminder_history?.map((reminder, index) => (
                        <div className="d-flex" key={index}>
                            <div className="sent-msg date-container" style={{minWidth: "120px"}}>
                                <h3>{dayjs(new Date(reminder.created_at)).format('DD MMM YY')}</h3>
                                {index !== reminder_history.length - 1 && (
                                    <div className="vertical-dotted-line"></div>
                                )}
                            </div>
                            <div className="message label-msg">
                                <label style={{wordWrap: 'break-word'}}>{reminder.compiled_text}</label>
                            </div>
                        </div>
                    ))}
                    {!!reminder_history?.length && showLoadMoreButton !== historyPageNum &&
                        <Box mt="10px" display="flex" justifyContent="center">
                            <Button onClick={() => loadMoreButton(historyPageNum + 1)}>Load More</Button>
                        </Box>
                    }
                </div>
            </Box>
            <div className="d-flex justify-content-between profile-fixed-button" style={{margin: "0px 45px"}}>
                <Button
                    bg="#FFFFFF"
                    text={isActiveEdit ? "Cancel" : "Edit"}
                    borderRadius="66px"
                    textColor="#D0004B"
                    width="40%"
                    onClick={() => handleCancel()}
                >
                    <span style={{color: "#D0004B"}}>{isActiveEdit ? "Cancel" : "Edit"}</span>
                </Button>
                {
                    isActiveEdit ?
                        <Button
                            type={isNext ? "primary" : "disabled"}
                            text="Save Details"
                            borderRadius="66px"
                            width="40%"
                            onClick={() => handleSubmit()}
                        />
                        :
                        <Button
                            type={getIfCurrentPaymentIsPending(payment_history) ? "primary" : "disabled"}
                            // bg="#D0004B"
                            text={is_sending_reminder ? 'Sending...' : 'Send Reminder'}
                            borderRadius="66px"
                            width="40%"
                            disabled={!getIfCurrentPaymentIsPending(payment_history) || is_sending_reminder}
                            onClick={() => sendReminder()}
                        />
                }
            </div>

            <DefaultModal
                show={isDisable}
                onHide={() => setIsDisable(false)}
                className="congrats-modal"
                style={{paddingRight: "0px"}}
                centered={true}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <DisableMemberModal
                    text={
                        "You will loose this member’s profile, details and payment history"
                    }
                    handleDelete={() => updateStatus()}
                    show={isDisable}
                    setshow={setIsDisable}
                />
            </DefaultModal>

            <DefaultModal
                show={isUpdatedSuccessfully}
                onHide={() => dispatch(setCustomerUpdateStatus(false))}
                className="add-mosque-modal"
                centered={true}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div>
                    <Icon
                        iconName="modalClose"
                        iconSize="xs"
                        style={{marginLeft: "auto"}}
                        onClick={() => dispatch(setCustomerUpdateStatus(false))}
                    />
                    <div className="text-center add-mosque-modal-content">
                        <Icon
                            iconName="congratulations"
                            style={{justifyContent: "center"}}
                        />
                        <div>Congratulations!</div>
                        <Box variant="text3" color="#7E7E7E">
                            We have saved the details of the member. You can <br/>
                            make further edits anytime.
                        </Box>
                        <button onClick={() => dispatch(setCustomerUpdateStatus(false))}>Continue</button>
                    </div>
                </div>
            </DefaultModal>

        </Box>
    )
}

export default ProfileModal
