import React from "react";
import Button from "../../../../../components/Buttons";
import styled from "styled-components";
import {useParams, Link} from "react-router-dom";

const DonateLink = styled(Link)`
  text-decoration: none;
`;

function Congratulation() {
    const handleClick = () => {
        window.location.href = "/login";
    }
  return (
    <div className="congrats-screen">
      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/congrats.svg" />
      <h4>Congratulations!</h4>
      <p>
       We will review all the information provided and get back to you within 48 hours. 
      </p>

      {/* <div className="d-flex justify-content-center mt-3">
      <DonateLink to={`/login`}>
           <button className="congratulation-button">Login Now</button>
      </DonateLink> */}
       {/* <button className="fill-bt" onClick={() => handleClick()}>Login Now</button> */}
      {/*  /!* <button className="fill-bt">Next Step</button> *!/*/}
      {/* </div> */}
    </div>
  );
}

export default Congratulation;
