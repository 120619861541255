import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "../../../components/Box";
import Icon from "../../../components/Icon/Icon";
import Button from "../../../components/Buttons";
import useOutsideClick from "../../../customHooks/useOutsideClick";
import DefaultModal from "../../../components/DefaultModal/DefaultModal";
import { useNavigate } from "react-router-dom";
import { getFormattedRelativeTime } from "../../../util/timeUtility";
import { memberApprove } from "../mosqueAction";
import { truncate } from "lodash";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const user = useSelector((state) => state.mosqueReducer.agent);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const approvalList = useSelector((state) => state.mosqueReducer.approvalList);
  const approvalListCount = useSelector(
    (state) => state.mosqueReducer.approvalListCount
  );

  const setInitialValue = () => {
    setIsShown(false);
  };

  const ref = useOutsideClick(() => setInitialValue());

  const handleClickProfile = () => {
    setIsShown(true);
  };

  const handleApproval = (memberSubId, approvalId) => {
    navigate(
      `/mosque/${activeEntity?._id}/members/add?memberSubId=${memberSubId}&approvalId=${approvalId}`
    );
    setShow(false);
  };

  const handleClickLink = (link) => {
    setIsShown(false);
    navigate(link);
  };

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("user_type");
    window.location = "/login";
  };

  return (
    <div ref={ref}>
      <div className="header_sec">
        <div className={open ? "header-flex" : "black-header-flex"}>
          <h1>Hello {truncate(user?.contact_name, { length: 20 })} 👋🏼,</h1>
          <div className="d-flex align-items-center">
            <div>
              <div className="noti-msg" onClick={() => setShow(true)}>
                {!show && (
                  <span className="notification">{approvalListCount}</span>
                )}
                {!show ? <Icon iconName="BellIcon" /> : <Box width="21px" />}
              </div>
            </div>
            <div
              className="admin-profile-links"
              style={{ cursor: "pointer" }}
              onClick={() => handleClickProfile()}
            >
              <div className="profile-im">
                {user?.profile_pic ? (
                  <img
                    src={user?.profile_pic}
                    width="45px"
                    height="45px"
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <Box
                    width="40px"
                    height="40px"
                    borderRadius="50%"
                    bg="#D0004B"
                  >
                    <div className={"text-center"} style={{ paddingTop: "8px", color: "white" }}>{user && user.contact_name ? user?.contact_name[0] : ""}</div>
                  </Box>
                )}
              </div>
              <div style={{ position: "absolute", right: "0px" }}>
                <Icon
                  iconName="rightIcon"
                  style={
                    isShown
                      ? { cursor: "pointer", transform: "rotate(180deg)" }
                      : { cursor: "pointer" }
                  }
                  iconSize="tiny"
                />
              </div>
            </div>
            {isShown && (
              <ul className="admin-profile-links-ul">
                <li
                  onClick={() =>
                    handleClickLink(`/mosque/${activeEntity?._id}/profile`)
                  }
                >
                  My Profile
                </li>
                {/*<li><Link to={`/mosque/${activeEntity?._id}/my-plans`}>My Plan</Link></li>*/}
                {/*<li><Link to={`/mosque/${activeEntity?._id}/pay-now`}>Pay Now</Link></li>*/}
                {/*<li onClick={() => handleClickLink(`/mosque/${activeEntity?._id}/admins?new`)}>Add Admin</li>*/}
                {/*<li onClick={() => handleClickLink(`/mosque/${activeEntity?._id}/admins?exist`)}>Exiting Admin</li>*/}
                <li onClick={() => handleLogOut()}>Logout</li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="notification-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="noti-msg-white" onClick={() => setShow(true)}>
          <span className="notification">{approvalListCount}</span>
          <Icon iconName="BellIconWhite" />
        </div>
        <div className="noti-popup">
          <div className="d-flex justify-content-between noti-heading">
            <h5>Notifications</h5>
            {/* <h6>Mark As Read</h6> */}
          </div>
          <div className="modal-scroll">
            {approvalList &&
              approvalList.map((item) => (
                <>
                  <div className="border-bottom mb-4 mt-3"></div>
                  <div className="msg-notification">
                    <span className="dot"></span>
                    <div className="bblue">{"#"}</div>
                    <div className="ml-4">
                      <p>
                        <b>{item?.task_name} </b>
                      </p>
                      <small>
                        {getFormattedRelativeTime(item?.created_at)}
                      </small>
                      <p>
                        <Box my="10px">
                          <Box>
                            <Button
                              type="outlineColor"
                              text="Review"
                              height="35px"
                              minHeight="35px"
                              onClick={() =>
                                handleApproval(item?.ref_id, item?._id)
                              }
                            />
                          </Box>
                        </Box>
                      </p>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </DefaultModal>
    </div>
  );
}

export default Header;
