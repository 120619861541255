import React from 'react'
import Button from '../../../../../components/Buttons/Button'

function DisableMemberModal() {
  return (
   
    <div className="congrats-screen">
    <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
    <h4>Disable Member</h4>
    <p>
    You will loose this member’s profile, details and payment history
    </p>

    <div className="d-flex justify-content-between mt-3">
    <Button
                  bg="#fff"
                  text="Cancel"
                  borderRadius= "66px"
                  textColor="#D0004B"
                  width="40%"
                  border= "1px solid #D0004B"
                  // disabled={user.phone_number ? "" : "true"}
                 
               />
               <Button
                  bg="#D0004B"
                  text="Disable Member"
                  borderRadius= "66px"
                  width="40%"
                  // disabled={user.phone_number ? "" : "true"}
                 
               />
    </div>
  </div>
 
  )
}

export default DisableMemberModal