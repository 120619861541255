import React, { useState } from "react";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon";
import { useSelector } from "react-redux";
import styled from "styled-components";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import DeletePlan from "./DeletePlan";

const PlanCards = styled.div`
  position: relative;
  overflow: hidden;
  padding: 30px 30px 10px 30px;
  background: #ffffff;
  border: 0.804144px solid #eff0f7;
  box-shadow: 0px 2.37681px 16.6376px rgba(98, 106, 128, 0.16);
  border-radius: 12px;
  margin-bottom: 20px;

  .planNo {
    font-weight: 700;
    font-size: 20px;
    color: #1a1a1a;
    text-decoration-line: underline;
  }

  .planNo span {
    font-weight: 700;
    font-size: 15px;
    color: #80cbc4;
    text-transform: uppercase;
    text-decoration-line: none;
  }

  .Dashborder {
    border-top: 1px dashed #a0aec0;
    margin: 10px 0;
  }
  .DashborderVertical {
    border-right: 1px dashed rgb(160, 174, 192);
    height: 41px;
    width: 1px;
  }

  .price {
    font-weight: 700;
    font-size: 38px;
    color: #1a1a1a;
  }

  .price del {
    font-weight: 600;
    font-size: 18px;
    color: #a0aec0;
    margin-left: 10px;
  }

  .planBtn {
    background: #a0aec0;
    border-radius: 200px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    height: 44px;
    width: 100%;
    border: none;
    margin: 10px 0;
  }
  .deleteBtn {
    font-weight: 400;
    font-size: 18px;
    color: #fe1717;
    border: none;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    span {
      margin-left: 10px;
    }
  }
  .editBtn {
    font-weight: 700;
    font-size: 18px;
    color: #d0004b;
    border: none;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    span {
      margin-left: 10px;
    }
  }

  .planDetail {
    font-weight: 600;
    font-size: 18px;
    color: #1a1a1a;
    margin: 15px 0;
  }

  ul {
    margin-bottom: 0px;
  }

  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: rgba(26, 26, 26, 0.67);
  }
`;

const ExistPlan = ({ handleEdit }) => {
  const plans = useSelector((state) => state.adminReducer.plans?.plan_list);
  const [show, setshow] = useState(false);
  const [id, setId] = useState("");
  const renderPlanName = (index) => {
    return "Plan";
  };

  const renderRecurringType = (type) => {
    if (type === "quarterly") {
      return "quarter";
    }
    if (type === "monthly") {
      return "month";
    }
    return "year";
  };

  const handleDelete = (flag, id) => {
    setshow(true);
    setId(id);
  }

  return (
    <div>
      <div className="row">
        {plans &&
          plans.map((item, index) => (
            <div className="col-md-4" key={index}>
              <PlanCards>
                <div className="planNo">
                  {renderPlanName(index)}-<span>{item?.plan_name}</span>
                </div>
                <div className="Dashborder"></div>
                <div className="d-flex align-items-center price">
                  {item.amount}
                  <del>8999</del>
                </div>

                <button className="planBtn planBtnDisable">Donate Now</button>
                <div className="planDetail">Plan Details</div>
                <ul>
                  <li>Billing Frequency - {item?.recurring_type}</li>
                  <li>
                    Payment Date - {item?.payment_day || "5"}th of every{" "}
                    {renderRecurringType(item?.recurring_type)}
                  </li>
                </ul>
                <div className="Dashborder"></div>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <button className="deleteBtn" onClick={() => handleDelete(true, item?._id)}>
                    <Icon iconName="delete" color="red" />
                    <span>Delete</span>
                  </button>
                  <div className="DashborderVertical"></div>
                  <button className="editBtn" onClick={() => handleEdit("edit", item)}>
                    <Icon iconName="AdminEdit" />
                    <span>Edit</span>
                  </button>
                </Box>
              </PlanCards>
            </div>
          ))}
      </div>

      <DefaultModal
        show={show}
        onHide={() => setshow(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <DeletePlan show={show} setshow={setshow} id={id} />
      </DefaultModal>
    </div>
  );
};

export default ExistPlan;
