import React, { useState, useEffect } from "react";
import Box from "../../../../../components/Box";
import AddCampaign from "./AddCampaign";
import PastCampaigns from "./PastCampaigns";
import { useSelector, useDispatch } from "react-redux";
import { fetchCampaign } from "../../../mosqueAction";

const INITIAL_SCREEN = "initial";
const ADD_NEW_SCREEN = "add";

const FundTab = () => {
    const dispatch = useDispatch();
    const [selectedSegment, setSelectedSegment] = useState(INITIAL_SCREEN);

    const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
    const campaign_page_num = useSelector((state) => state.mosqueReducer.campaign_page_num);
    const campaign_page_size = useSelector((state) => state.mosqueReducer.campaign_page_size);

    useEffect(() => {
        if (activeEntity?._id && activeEntity?.organisation_id) {
            let body = {};
            body.pageNum = campaign_page_num;
            body.pageSize = campaign_page_size;
            body.filters = {};
            body.filters.entity_id = activeEntity?._id;
            dispatch(fetchCampaign(body));
        }
    }, [activeEntity]);

    const setSegment = (segment) => {
    setSelectedSegment(segment);
  };

  const pathName = window.location.search;

  useEffect(() => {
    if (pathName === "?new") {
      setSelectedSegment(ADD_NEW_SCREEN);
    }
  }, [pathName]);

  return (
    <div>
      <Box display="flex" alignItems="center" cursor="pointer" justifyContent="space-between">
        <Box fontSize="22px" fontWeight={700}>{selectedSegment === INITIAL_SCREEN && "Fund Me"}</Box>
        <ul className="d-flex plan-modal">
          <li
            className="information col-6"
            style={{
              fontWeight: selectedSegment === INITIAL_SCREEN ? "600" : "600",
              backgroundColor:
                selectedSegment === INITIAL_SCREEN ? "#D0004B" : "#fff",
              color: selectedSegment === INITIAL_SCREEN ? "#fff" : "#D0004B",
              borderRadius:
                selectedSegment === INITIAL_SCREEN
                  ? "50px 0px 0px 50px"
                  : "50px 0px 0px 50px",
            }}
            onClick={() => setSegment(INITIAL_SCREEN)}
          >
            Past Campaigns{" "}
          </li>
          <li
            className="screen-details col-6"
            style={{
              fontWeight: selectedSegment === ADD_NEW_SCREEN ? "600" : "600",
              backgroundColor:
                selectedSegment === ADD_NEW_SCREEN ? "#D0004B" : "#fff",
              color: selectedSegment === ADD_NEW_SCREEN ? "#fff" : "#D0004B",
              borderRadius:
                selectedSegment === ADD_NEW_SCREEN
                  ? "0px 50px 50px 0px"
                  : "0px 50px 50px 0px",
            }}
            onClick={() => setSegment(ADD_NEW_SCREEN)}
          >
            Add Campaign{" "}
          </li>
        </ul>
      </Box>
      <Box mt="25px">
        {selectedSegment === INITIAL_SCREEN ? <PastCampaigns /> : <AddCampaign />}
      </Box>
    </div>
  );
}

export default FundTab;
