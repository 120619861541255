import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons/Button";
import { fetchOrganisationEntities } from "../../../mosqueAction";
import { showNotification } from "../../../../../util/Utility";
import { callApiV2 } from "../../../../../util/apiCaller";
import ErrorMessage from "../../../../../components/ErrorMessage";

const MosqueForm = () => {
    const dispatch = useDispatch();

    const [entity, setEntityData] = useState({
        name: "",
        address: "",
        pincode: "",
        country: "",
    });
    const [isNext, setIsNext] = useState(false)
    const [errors, setErrors] = useState({});

    const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
    const agent = useSelector((state) => state.mosqueReducer.agent);

    useEffect(() => {
        setEntityData({
            ...activeEntity,
            entity_id: activeEntity._id,
        });
    }, [activeEntity, agent]);

    const handleChangeUser = (keyName, keyValue) => {
        setIsNext(true);
        let update = { ...entity };
        update[keyName] = keyValue;
        setEntityData(update);
    };

    const handleValidation = () => {
        let isValid = true;
        let error = {};
        if (!entity.name.trim()) {
            isValid = false;
            error["name"] = "Please enter mosque name";
        }
        return {
            isValid,
            error
        };
    }

    const handleSubmit = async () => {
        setErrors({});
        let validate = handleValidation();
        if (validate.isValid) {
            let update = { ...entity };

            const trimmedDetails = {};
            // Trim each attribute and assign it to trimmedDetails object
            for (const key in update) {
                if (typeof update[key] === "string") {
                    trimmedDetails[key] = update[key].trim();
                } else {
                    trimmedDetails[key] = update[key];
                }
            }

            const res = await callApiV2({
                method: "post",
                endpoint: `entity/${activeEntity._id}/update`,
                body: {
                    entity: trimmedDetails,
                },
            });
            if (res?.status === "Success") {
                showNotification("success", "Update Details successfully.");
                dispatch(fetchOrganisationEntities(activeEntity.organisation_id));
            }
        } else {
            setErrors(validate.error);
        }
    }

    return (
        <div className="add_form p-4 form-height form_width">
            <div className="mosque-form">
                <h2>Mosque Details</h2>
                <div className="row">
                    <div className="col-lg-6 input-width pt-4 mb-4">
                        <label>
                            Mosque Name<span style={{ color: "#FE1717" }}>*</span>
                        </label>
                        <Input
                            value={entity.name}
                            onChange={(e) => handleChangeUser("name", e.target.value)}
                            type="text"
                            placeholder="Enter mosque name"
                            className="input-box"
                        />
                        {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                    </div>
                    <div className="col-lg-6 input-width pt-4 mb-4">
                        <label>
                            Mosque Address<span style={{ color: "#FE1717" }}>*</span>
                        </label>
                        <Input
                            isDisabled
                            value={entity.address}
                            onChange={(e) => handleChangeUser("address", e.target.value)}
                            type="text"
                            placeholder="Enter mosque address"
                            className="input-box"
                        />
                    </div>

                    <div className="col-lg-6 pt-4 input-width">
                        <label>
                            Post Code<span style={{ color: "#FE1717" }}>*</span>
                        </label>
                        <Input
                            isDisabled
                            value={entity.pincode}
                            onChange={(e) => handleChangeUser("pincode", e.target.value)}
                            type="text"
                            placeholder="123456"
                            className="input-box"
                        />
                    </div>
                    <div className="col-lg-6 pt-4 input-width">
                        <label>
                            Mosque Country<span style={{ color: "#FE1717" }}>*</span>
                        </label>
                        <Input
                            isDisabled
                            value={entity.country}
                            onChange={(e) => handleChangeUser("country", e.target.value)}
                            type="text"
                            placeholder="Enter mosque country"
                            className="input-box"
                        />
                    </div>
                </div>
                <Button
                    type={!isNext ? "disabled" : "primary"}
                    onClick={() => handleSubmit()}
                    text="Save Details"
                    borderRadius="66px"
                    textColor="#fff"
                    width="15%"
                    mt="4"
                    mx="auto"
                    height="60px"
                />
                <br />
            </div>
        </div>
    );
}

export default MosqueForm;
