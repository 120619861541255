import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/Input/Input";
import "../../../../../css/addmember.css";
import UploadFile from "../../../../../components/UplodeFile/UploadFile";
import Button from "../../../../../components/Buttons/Button";
import {
  addNewCampaign,
  fetchSingleCampaign,
  updateCampaign,
} from "../../../mosqueAction";
import { useParams } from "react-router-dom";
import shortid from "shortid";
import _, { isEmpty } from "lodash";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { DatePicker } from "antd";
import styled from "styled-components";

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }
  .ant-picker-focused {
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1A1A1A
  }
`;

function AddCampaign() {
  const dispatch = useDispatch();
  const { campaign_id } = useParams();

  const [errorData, setErrors] = useState({});
  const [isNext, setIsNext] = useState(false);

  const agent = useSelector((state) => state.mosqueReducer.agent);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const addCampaign = useSelector((state) => state.mosqueReducer.add_campaign);
  const campaignList = useSelector((state) => state.mosqueReducer.campaign_list);

  const [campaignData, setCampaignData] = useState({
    title: "",
    targetAmount: "",
    startDate: null,
    img_url: "",
    img_url_alt: "",
    currency: "",
  });

  const formValidation = () => {
    return !!campaignData?.title.trim() &&
        !!campaignData?.targetAmount &&
        !!campaignData?.startDate;
  }

  useEffect(() => {
    if (campaignData) {
      setIsNext(formValidation())
    }
  }, [campaignData])

  useEffect(() => {
    if (addCampaign && addCampaign._id) {
      let newCampaign = { ...addCampaign };
      if (_.isArray(newCampaign.tags)) {
        newCampaign.tags = addCampaign?.tags?.join(",");
      }
      setCampaignData(addCampaign);
    }
  }, [addCampaign]);

  useEffect(() => {
    if (campaign_id && !addCampaign?._id) {
      dispatch(fetchSingleCampaign(campaign_id));
    }
  }, [addCampaign, campaign_id]);


  const handleChangeUser = (keyName, keyValue, value2) => {
    let update = { ...campaignData };
    if (keyName === "startDate") {
      update["startDate"] = new Date(keyValue?.$d).toISOString()
    }
    if (keyName === "img_url") {
      update["img_url"] = keyValue;
      update['img_url_alt'] = value2
    }
    update[keyName] = keyValue;
    setCampaignData(update);
  };

  const onOptionAuthorImage = (details, name) => {
    handleChangeUser('img_url', details, name);
  }

  const validateCampaign = (campaignData) => {
    let isValid = true;
    let message = {};
    if (!campaignData.title) {
      isValid = false;
      message["title"] = "Campaign Name Cannot Be Empty";
    }
    if (!campaignData.targetAmount) {
      isValid = false;
      message["targetAmount"] = "Amount Cannot Be Empty";
    }
    if (!campaignData.startDate) {
      isValid = false;
      message["startDate"] = "Activating Date Cannot Be Empty";
    }
    return {
      isValid,
      message,
    };
  };

  const saveData = () => {
    let campaignDetails = { ...campaignData };
    const newCampaign = {};
    // Trim each attribute and assign it to trimmedDetails object
    for (const key in campaignDetails) {
      if (typeof campaignDetails[key] === "string") {
        newCampaign[key] = campaignDetails[key].trim();
      } else {
        newCampaign[key] = campaignDetails[key];
      }
    }

    let check = validateCampaign(campaignData);
    if (check.isValid) {
      if (addCampaign && addCampaign?._id) {
        dispatch(updateCampaign(addCampaign?._id, newCampaign));
      } else {
        newCampaign.entity_id = activeEntity?._id;
        newCampaign.organizer = agent?._id;
        newCampaign.position = campaignList?.length + 1;
        newCampaign.url = `${_.kebabCase(
          newCampaign.title
        )}-${shortid.generate()}`;

        if (newCampaign.tags && typeof newCampaign.tags === "string") {
          newCampaign.tags = newCampaign.tags.split(",");
        }

        if (newCampaign) {
          dispatch(addNewCampaign(newCampaign));
          setCampaignData({
            title: "",
            targetAmount: "",
            startDate: null,
            img_url: "",
            img_url_alt: "",
            currency: "",
          });
        }
      }
    } else {
      setErrors(check.message);
    }
  };

  return (
    <div className="add_form p-4">
      <div className="mosque-form">
        <div className="row margin-mosque">
          <div className="col-lg-6">
            <label>
              Campaign Name
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={campaignData.title}
              onChange={(e) => handleChangeUser("title", e.target.value)}
              type="text"
              placeholder="Enter Campaign Name"
              padding="0px 10px"
              className="input-box fund-input"
            />
            {errorData?.title && (
              <ErrorMessage>{errorData?.title}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6">
            <label>
              Amount<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={campaignData.targetAmount}
              currencySymbols={true}
              setCurrencySymbols={(e) => handleChangeUser("currency", e)}
              onChange={(e) => handleChangeUser("targetAmount", e.target.value)}
              border={
                campaignData.targetAmount.length ? "1px solid #606734" : null
              }
              placeholder="Enter Amount"
              className="input-box fund-input"
              type="number"
              pattern="[0-9]"
            />
            {errorData?.targetAmount && (
              <ErrorMessage>{errorData?.targetAmount}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-4">
            <label>
              Activating Date<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <div className="date_class">
              <DatePickerBox>
                <DatePicker onChange={(value) => handleChangeUser("startDate", value)} />
              </DatePickerBox>
              {errorData?.startDate && (
                <ErrorMessage>{errorData?.startDate}</ErrorMessage>
              )}
            </div>
          </div>
          <div className="col-lg-6 pt-4">
            <label>Image</label>
            <UploadFile
                id="author"
                onOptionClick={(data, name) => onOptionAuthorImage(data, name)}
                fileName={campaignData?.img_url_alt}
                bg="#fff"
                height="66px"
            />
          </div>
        </div>
        <Button type={!isNext ? "disabled" : "primary"}
          onClick={() => saveData()}
          text="Save Details"
          borderRadius="66px"
          textColor="#fff"
          width="15%"
          mt="4"
          mx="auto"
          height="60px"
        />
        <br />
      </div>
    </div>
  );
}

export default AddCampaign;
