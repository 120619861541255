import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../../../../css/addmember.css";
import Button from "../../../../../components/Buttons/Button";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import Icon from "../../../../../components/Icon/Icon";
import Input from "../../../../../components/Input";
import {
    addNewMosque,
    entityApprove,
    entityDecline,
    setMosqueDetails,
    updateAddedNewFlag,
    updateMosque
} from "../../../adminAction";
import {DatePicker} from "antd";
import {getCountryOption, getPaymentMethodOption, showNotification} from "../../../../../util/Utility";
import Dropdown from "../../../../../components/Dropdown";
import styled from "styled-components";
import dayjs from "dayjs";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import {UPDATE_APPROVAL_STATUS, UPDATE_DECLINE_STATUS} from "../../../adminActionType";
import {useNavigate} from "react-router-dom";
import ErrorMessage from "../../../../../components/ErrorMessage";

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker-focused {
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1a1a1a;
  }
`;

const paymentMethodOption = getPaymentMethodOption();

const countryOptions = getCountryOption();

const MosqueForm = ({detailsMosque = null, entityId = null, approvalId = null}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const [details, setDetails] = useState({});
    const [isDecline, setIsDecline] = useState(false);

    const isApprovedApproval = useSelector((state) => state.adminReducer.isApprovedApproval);
    const isApprovalDecline = useSelector((state) => state.adminReducer.isApprovalDecline);
    const mosqueDetails = useSelector((state) => state.adminReducer.mosqueDetails);
    const plan_list = useSelector((state) => state.adminReducer.plans?.plan_list);
    const isAddedNew = useSelector((state) => state.adminReducer.isAddedNew);

    useEffect(() => {
        if (detailsMosque) {
            const data = {};
            data.entity_name = detailsMosque?.name;
            data.entity_address = detailsMosque?.address;
            data.entity_pincode = detailsMosque?.pincode;
            data.entity_country = detailsMosque?.country;
            data.entity_merchant_key = detailsMosque?.entity_merchant_key;
            data.entity_merchant_id = detailsMosque?.entity_merchant_id;
            data.contact_name = detailsMosque?.primary_user_id?.contact_name;
            data.contact_email = detailsMosque?.primary_user_id?.contact_email;
            data.contact_phone = detailsMosque?.primary_user_id?.contact_phone;
            data.country_code = detailsMosque?.primary_user_id?.country_code;
            data.contact_address = detailsMosque?.primary_user_id?.address;
            data.payment_method = detailsMosque?.organisation_id?.payment_method;
            data.saas_subscription_plan_id =
                detailsMosque?.organisation_id?.saas_subscription_plan_id;
            data.membership_date = detailsMosque?.organisation_id?.membership_date ?? detailsMosque?.organisation_id?.created_at;
            setDetails(data);
        } else {
            setDetails(mosqueDetails);
        }
    }, [detailsMosque, mosqueDetails]);

    const formValidation = () => {
        return !!details?.entity_name &&
            !!details?.entity_address &&
            !!details?.entity_pincode &&
            !!details?.entity_country &&
            !!details?.contact_name &&
            !!details?.contact_email &&
            !!details?.membership_date
    }

    useEffect(() => {
        if (details) {
            setIsNext(formValidation());
        }
    }, [details])

    const handleChangeUser = (keyName, keyValue) => {
        if (approvalId) {
            let update = {...details};
            update[keyName] = keyValue;
            setDetails(update);
        } else {
            dispatch(setMosqueDetails(keyName, keyValue));
        }
    };

    const optionsPlan = () => {
        let listValues = [];
        if (plan_list) {
            const list = plan_list?.forEach((reply, index) => {
                listValues.push({
                    ...reply,
                    label: reply?.plan_name,
                    value: reply?._id,
                });
            });
        }
        return listValues;
    };

    const handleAddMosque = () => {
        let update = {};
        update.entity_merchant_id  = details.entity_merchant_id;
        update.entity_merchant_key  = details.entity_merchant_key;
        if (update.entity_merchant_id && update.entity_merchant_key) {
            dispatch(addNewMosque(true));
        } else {
            showNotification("error", "Mosque cannot be approved with incomplete merchant details");
        }
    };

    const handleAddNew = () => {
        dispatch(updateAddedNewFlag(false));
        window.location.href = "/admin/mosque/add"
    };

    useEffect(() => {
        if (isApprovalDecline) {
            dispatch({
                type: UPDATE_DECLINE_STATUS,
                isApprovalDecline: false
            });
            setDetails(mosqueDetails);
            setIsDecline(false);
            navigate("/admin/mosque/add");
        }
    }, [isApprovalDecline])

    const handleApproval = () => {
        dispatch({
            type: UPDATE_APPROVAL_STATUS,
            isApprovedApproval: false
        });

        window.location.href = `/admin/mosque/add`;
    }

    const handleClickDecline = () => {
        if (approvalId) {
            dispatch(entityDecline(approvalId));
        }
    }

    const handleClickApproval = () => {
        if (entityId && approvalId) {
            let update = {};
            update.entity_merchant_id  = details.entity_merchant_id;
            update.entity_merchant_key  = details.entity_merchant_key;
            if (update.entity_merchant_id && update.entity_merchant_key) {
                dispatch(updateMosque(entityId, update, true));
                dispatch(entityApprove(entityId, approvalId));
            } else {
                showNotification("error", "Mosque cannot be approved with incomplete merchant details");
            }
        }
    }

    const getRenderButton = () => {
        if (detailsMosque) {
            return (
                <Box display="flex" justifyContent="center">
                    <Box>
                        <Button
                            type="outlineColor"
                            text="Decline Mosque"
                            borderRadius="66px"
                            minWidth="200px"
                            mt="4"
                            mx="auto"
                            height="50px"
                            onClick={() => setIsDecline(true)}
                        />
                    </Box>
                    <Box ml="15px">
                        <Button
                            type="primary"
                            text="Approve Mosque"
                            borderRadius="66px"
                            minWidth="200px"
                            mt="4"
                            mx="auto"
                            height="50px"
                            onClick={() => handleClickApproval()}
                        />
                    </Box>
                </Box>
            );
        } else {
            return (
                <Button
                    type={!isNext ? "disabled" : "primary"}
                    text="Save Details"
                    borderRadius="66px"
                    width="15%"
                    mt="4"
                    mx="auto"
                    height="60px"
                    onClick={() => handleAddMosque()}
                />
            );
        }
    };

    return (
        <div className="add_form p-4 form_width">
            <div className="mosque-form" style={{paddingRight: "80px"}}>
                <h2>Add Mosque</h2>
                <div className="row">
                    <div className="col-lg-6">
                        <label>
                            Mosque Name<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <Input
                            isDisabled={!!approvalId}
                            value={details?.entity_name}
                            onChange={(e) => handleChangeUser("entity_name", e.target.value)}
                            type="text"
                            placeholder="Enter mosque name"
                            className="input-box"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label>
                            Mosque Address<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                           disabled={!!approvalId}
                            value={details?.entity_address}
                            onChange={(e) =>
                                handleChangeUser("entity_address", e.target.value)
                            }
                            type="text"
                            placeholder="Enter mosque address"
                            className="input-box"
                        />
                    </div>

                    <div className="col-lg-6">
                        <label>
                            Post Code<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                            disabled={!!approvalId}
                            value={details?.entity_pincode}
                            onChange={(e) =>
                                handleChangeUser("entity_pincode", e.target.value)
                            }
                            type="text"
                            placeholder="Enter post code"
                            className="input-box"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label>
                            Mosque Country<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <Dropdown
                            allowSearch
                            variant={!!approvalId ? "disabled" : "outlinedGreen"}
                            defaultText="Select type"
                            color="#1a1a1aad"
                            fontWeight="500"
                            width="100%"
                            height="54px"
                            Icon="none"
                            selectedValue={details?.entity_country}
                            onOptionClick={(item) => handleChangeUser("entity_country", item?.value)}
                            options={countryOptions}
                        />
                    </div>
                    <div className="col-lg-6 customDatePicker">
                        <label>
                            Membership Since<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <br></br>
                        <DatePickerBox>
                            <DatePicker
                                defaultValue={dayjs()}
                                value={dayjs(details?.membership_date, "YYYY-MM-DD")}
                                onChange={(value) => handleChangeUser("membership_date", value)}
                            />
                        </DatePickerBox>
                    </div>
                    <div className="col-lg-6">
                        <label>
                            Admin Name<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <Input
                            isDisabled={!!approvalId}
                            value={details.contact_name}
                            onChange={(e) => handleChangeUser("contact_name", e.target.value)}
                            type="text"
                            placeholder="Enter Admin Name"
                            className="input-box"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label>
                            Email Address<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <Input
                            isDisabled={!!approvalId}
                            value={details.contact_email}
                            onChange={(e) =>
                                handleChangeUser("contact_email", e.target.value)
                            }
                            type="text"
                            placeholder="Enter Email Address"
                            className="input-box"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label>
                            Contact Number
                            {/* <span style={{ color: "#FE1717" }}>*</span> */}
                        </label>
                        <Input
                            isDisabled={!!approvalId}
                            value={details.contact_phone}
                            countryCodes={true}
                            setCountryCode={(e) => handleChangeUser("country_code", e)}
                            onChange={(e) =>
                                handleChangeUser("contact_phone", e.target.value)
                            }
                            // border={user.phone_number.length ? "1px solid #606734" : null}
                            placeholder="785-785-4644"
                            className="input-box"
                            type="number"
                            pattern="[0-9]"
                            height="52px"
                            overflow="unset"
                        />
                    </div>
                    <div className="col-lg-6">
                        <label>
                            Merchant Id<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                            value={details?.entity_merchant_id}
                            onChange={(e) => handleChangeUser("entity_merchant_id", e.target.value)}
                            type="text"
                            placeholder="Enter Merchant Id"
                            className="input-box"
                        />
                        {errors.entity_merchant_id && <ErrorMessage>{errors.entity_merchant_id}</ErrorMessage>}
                    </div>
                    <div className="col-lg-6">
                        <label>
                            Merchant Secret Key<span style={{color: "#FE1717"}}>*</span>
                        </label>
                        <input
                            value={details?.entity_merchant_key}
                            onChange={(e) => handleChangeUser("entity_merchant_key", e.target.value)}
                            type="text"
                            placeholder="Enter Merchant Secret Key"
                            className="input-box"
                        />
                        {errors.entity_merchant_key && <ErrorMessage>{errors.entity_merchant_key}</ErrorMessage>}
                    </div>
                    {/* <div className="col-lg-6">
            <label>
              Admin’s Address
             
            </label>
            <input
              value={details.contact_address}
              onChange={(e) =>
                handleChangeUser("contact_address", e.target.value)
              }
              type="text"
              placeholder="Enter Address"
              className="input-box"
            />
          </div> */}
                </div>
                {getRenderButton()}
                <br/>
                <DefaultModal
                    show={isAddedNew}
                    onHide={() => dispatch(updateAddedNewFlag(false))}
                    className="add-mosque-modal"
                    centered={true}
                    showHeader={false}
                    size=""
                    showFooter={false}
                >
                    <div>
                        <Icon
                            iconName="modalClose"
                            iconSize="xs"
                            style={{marginLeft: "auto"}}
                            onClick={() => dispatch(updateAddedNewFlag(false))}
                        />
                        <div className="text-center add-mosque-modal-content">
                            <Icon
                                iconName="congratulations"
                                style={{justifyContent: "center"}}
                            />
                            <div>Congratulations!</div>
                            <div>
                                We have added the new mosque. You can make edits to existing
                                mosque anytime.
                            </div>
                            <button onClick={() => handleAddNew()}>Add New Mosque</button>
                        </div>
                    </div>
                </DefaultModal>

                <DefaultModal
                    show={isApprovedApproval}
                    onHide={() => handleApproval()}
                    className="add-mosque-modal"
                    centered={true}
                    showHeader={false}
                    size=""
                    showFooter={false}
                >
                    <div>
                        <Icon
                            iconName="modalClose"
                            iconSize="xs"
                            style={{marginLeft: "auto"}}
                            onClick={() => handleApproval()}
                        />
                        <div className="text-center">
                            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/congrats.svg"/>
                            <Label
                                justifyContent="center"
                                fontSize="20px"
                                fontWeight={700}
                                my="20px"
                            >
                                Congratulations!
                            </Label>
                            <Label variant="text3" my="20px" color="#7E7E7E">
                                We have added the new mosque. You can make edits to existing mosque anytime.
                            </Label>
                        </div>
                    </div>
                </DefaultModal>

                <DefaultModal
                    show={isDecline}
                    onHide={() => setIsDecline(false)}
                    className="add-mosque-modal"
                    centered={true}
                    showHeader={false}
                    size=""
                    showFooter={false}
                >
                    <div>
                        <Icon
                            iconName="modalClose"
                            iconSize="xs"
                            style={{marginLeft: "auto"}}
                            onClick={() => setIsDecline(false)}
                        />
                        <div className="text-center">
                            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg"/>
                            <Label
                                justifyContent="center"
                                fontSize="20px"
                                fontWeight={700}
                                my="20px"
                            >
                                Decline Mosque
                            </Label>
                            <Label variant="text3" my="20px" color="#7E7E7E">
                                You will permanently loose this mosque’s details and other
                                information.
                            </Label>
                            <Box className="test" display="flex" justifyContent="center">
                                <Button
                                    className="test"
                                    type="outlineColor"
                                    minWidth="200px !important"
                                    width="200px !important"
                                    height="45px !important"
                                    mr="20px !important"
                                    onClick={() => setIsDecline(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    minWidth="200px !important"
                                    width="200px !important"
                                    height="45px !important"
                                    mr="20px !important"
                                    onClick={() => handleClickDecline()}
                                >
                                    Decline Mosque
                                </Button>
                            </Box>
                        </div>
                    </div>
                </DefaultModal>
            </div>
        </div>
    );
}

export default MosqueForm;
