import React, {useEffect, useState} from "react";
import styled from "styled-components";
import moment from "moment";

import Box from "../../../../components/Box";
import Icon from "../../../../components/Icon";
import Label from "../../../../components/Label";
import LineChart from "../../../../components/D3Charts/LineChart";
import BarChart from "../../../../components/D3Charts/BarChart";
import {useDispatch, useSelector} from "react-redux";
import {fetchOrgRevenueStats, fetchOrgSubsCount} from "../../adminAction";
import dayjs from "dayjs";
import CheckBoxDateRange from "./components/CheckBoxDateRange";


const BoxContainer = styled(Box)`
  background: #f5f5f5;
  width: 160px;
  padding: 10px;
  border-radius: 8px;
  margin-left: 10px;
  height: 38px;
`;
const FinancialBoxShadow = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
  0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 20px;
`;
const MonthlyDetails = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #7e7e7e;
  margin-bottom: 8px;

  span {
    color: #000000;
  }
`;
const TotalMosque = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1a1a1a;

  span {
    color: #7e7e7e;
    font-weight: 400;
    margin-left: 10px;
  }
`;
const RevenueDetails = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #7e7e7e;
  line-height: 23px;

  span {
    font-weight: 700;
    color: #000000;
  }
`;
const Percent = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #1a1a1a;
`;
const ProgressBarCustom = styled.div`
  background: #d3edea;
  border-radius: 80px;
  width: 100%;
  height: 10px;
  margin: 25px 0 15px 0;
`;
const ProgressStatus = styled(Box)`
  background: #00ac4f;
  border-radius: 80px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .statusPointer {
    cursor: pointer;
    width: 18px;
    height: 18px;
    background: #d0004b;
    border-radius: 50%;
    border: 4px solid #ffffff;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  }
`;

const AdminFinancials = () => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({});
    const [dateRange, setDateRange] = useState("2023");

    const statsDetails = useSelector((state) => state.adminReducer.dashboardStats);
    const orgRevenueStats = useSelector(state => state.adminReducer.orgRevenueStats);
    const orgSubscriptionCount = useSelector(state => state.adminReducer.orgSubscriptionCount);

    const handleRevenueByYear = (year) => {
        const start_date = moment().year(year).startOf('year').subtract(1, 'years').toISOString();
        const end_date = moment().year(year).endOf('year').toISOString();
        dispatch(fetchOrgRevenueStats(start_date, end_date));
    }

    useEffect(() => {
        handleRevenueByYear(dateRange);

        const startDate = moment().startOf('year').toISOString();
        const endDate = moment().endOf('year').toISOString();
        dispatch(fetchOrgSubsCount(startDate, endDate))
    }, []);

    useEffect(() => {
        if (orgRevenueStats?.length > 0) {
            const result = {};
            orgRevenueStats.forEach(data => {
                const {year, month} = data._id;
                const {amount} = data
                if (!result[year]) {
                    result[year] = Array(12).fill(0); // Initialize the array with zeros for all months
                    setDetails(result);
                }
                result[year][month - 1] = amount; // Update the amount for the corresponding month
                setDetails(result);
            });
        }
    }, [orgRevenueStats]);


    const transformedResult = Object.entries(details).map(([year, data]) => ({
        name: year.toString(),
        data: data
    }));

    const getTransformedDataForSubsCount = (orgSubscriptionCount) => {
        let transformedResultForOrgSubsCount = [
            {
                name: "Total",
                data: Array(12).fill(0)
            }
        ];
        if (orgSubscriptionCount && orgSubscriptionCount.length > 0) {
            orgSubscriptionCount?.forEach(item => {
                const {_id, count} = item;
                if (count) {
                    transformedResultForOrgSubsCount[0].data = transformedResultForOrgSubsCount[0].data.map((dataItem, index) => {
                        if (index + 1 === _id.month) {
                            return count;
                        }
                        return dataItem;
                    });
                }
            });
        }
        // console.log("transformedResultForOrgSubsCount", transformedResultForOrgSubsCount);
        return transformedResultForOrgSubsCount;
    }

    let cards = [
        {
            "total_key": "total_entity_monthly_count",
            "current_month_stats": "current_month_monthly_plan_stats"
        },
        {
            "total_key": "total_entity_quarterly_count",
            "current_month_stats": "current_month_quarterly_plan_stats"
        },
        {
            "total_key": "total_entity_yearly_count",
            "current_month_stats": "current_month_yearly_plan_stats"
        }
    ];

    const getTotalOfAllCards = () => {
        let sum = 0;
        cards.forEach(item => {
            sum += statsDetails[item.total_key.toString()]
        })
        return sum;
    };

    const financialperiod = (total_key) => {
        if (total_key === "total_entity_monthly_count") {
            return "Monthly";
        } else if (total_key === "total_entity_quarterly_count") {
            return "Quaterly";
        } else {
            return "Yearly";
        }
    };

    const renderPercentage = (statsDetails, total_key, getTotalOfAllCards) => {
        if (statsDetails[total_key] === 0) {
            return 0;
        }
        return statsDetails[total_key] / getTotalOfAllCards() * 100;
    }

    const getCards = ({total_key, current_month_stats}) => {
        return (
            <div className="col-md-4">
                <FinancialBoxShadow>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div>
                            <MonthlyDetails>
                                {financialperiod(total_key)} Mosques:{" "}
                                <span>{statsDetails[total_key]}</span>
                            </MonthlyDetails>
                            <RevenueDetails>
                                Revenue received:{" "}
                                <span>
                    {statsDetails && statsDetails[current_month_stats] && statsDetails[current_month_stats][0] ? statsDetails[current_month_stats][0].total_amount : 0}
                  </span>
                            </RevenueDetails>
                            <RevenueDetails>
                                Pending revenue:{" "}
                                <span>
                    {statsDetails && statsDetails[current_month_stats] && statsDetails[current_month_stats][0] ? statsDetails[current_month_stats][0].total_pending_amount : 0}


                  </span>
                            </RevenueDetails>
                        </div>
                        <div>
                            <Icon iconName="financialYellow"/>
                        </div>
                    </div>
                    <ProgressBarCustom>
                        <ProgressStatus
                            width={`${(statsDetails[total_key] / getTotalOfAllCards() * 100)}%`}
                        >
                            <div className="statusPointer"></div>
                        </ProgressStatus>
                    </ProgressBarCustom>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <TotalMosque>
                            {statsDetails[total_key]}/
                            {getTotalOfAllCards()}
                            <span>Total Mosques</span>
                        </TotalMosque>
                        <Percent>
                            {renderPercentage(statsDetails, total_key, getTotalOfAllCards())}
                            %
                        </Percent>
                    </div>
                </FinancialBoxShadow>
            </div>
        )
    };

    const handleDateRange = (selection) => {
        setDateRange(selection);
        handleRevenueByYear(selection);
    }

    const getCategories = () => {
        let data = Array(12).fill('');
        data = data.map((item, index) => dayjs().startOf('year').add(index, 'month').format('MMM'));
        return data;
    }

    const getData = () => {
        let amount_data = Array(12).fill(0);
        let fee_data = Array(12).fill(0);
        if (orgRevenueStats?.length > 0) {
            amount_data = amount_data.map((item, index) => {
                let count = 0;
                orgRevenueStats?.forEach(element => {
                    if (element._id.month === index + 1) {
                        count = element.amount;
                    }
                });
                return count;
            })
            fee_data = fee_data.map((item, index) => {
                let count = 0;
                orgRevenueStats?.forEach(element => {
                    if (element._id.month === index + 1) {
                        count = element.admin_fee;
                    }
                });
                return count;
            })
        }
        let series = [
            {
                name: "Amount",
                data: amount_data
            },
            {
                name: "Fee",
                data: fee_data
            }
        ];
        return series;
    }

    return (
        <Box width="100%" maxWidth="100%">
            {/*<div className="row mb-5" style={{ width: "100%", maxWidth: "100%" }}>*/}
            {/*    /!*{cards?.map(item => getCards(item))}*!/*/}
            {/*</div>*/}
            <Box width="100%" maxWidth="100%" mb="70px">
                <FinancialBoxShadow>
                    <Label fontSize="24px" fontWeight="700" justifyContent="center">
                        New Subscribed Mosques
                    </Label>
                    <br></br>
                    <br/>
                    {/*<Label*/}
                    {/*    style={{display: "flex", margin: "auto"}}*/}
                    {/*    fontSize="18px"*/}
                    {/*    fontWeight="500"*/}
                    {/*    justifyContent="center"*/}
                    {/*>*/}
                    {/*    <div*/}
                    {/*        style={{display: "flex", margin: "0px 10px"}}*/}
                    {/*        justifyContent="center"*/}
                    {/*    >*/}
                    {/*        <div*/}
                    {/*            style={{*/}
                    {/*                height: "30px",*/}
                    {/*                width: "50px",*/}
                    {/*                backgroundColor: "#FFD700",*/}
                    {/*                borderRadius: "5px",*/}
                    {/*            }}*/}
                    {/*        ></div>*/}
                    {/*        <p style={{margin: "0px 8px"}}>Monthly Plan</p>*/}
                    {/*    </div>*/}
                    {/*    <div style={{display: "flex", margin: "0px 10px"}}>*/}
                    {/*        <div*/}
                    {/*            style={{*/}
                    {/*                height: "30px",*/}
                    {/*                width: "50px",*/}
                    {/*                backgroundColor: "#DC143C",*/}
                    {/*                borderRadius: "5px",*/}
                    {/*            }}*/}
                    {/*        ></div>*/}
                    {/*        <p style={{margin: "0px 8px"}}>Quaterly Plan</p>*/}
                    {/*    </div>*/}
                    {/*    <div style={{display: "flex", margin: "0px 10px"}}>*/}
                    {/*        <div*/}
                    {/*            style={{*/}
                    {/*                height: "30px",*/}
                    {/*                width: "50px",*/}
                    {/*                backgroundColor: "#80CBC4",*/}
                    {/*                borderRadius: "5px",*/}
                    {/*            }}*/}
                    {/*        ></div>*/}
                    {/*        <p style={{margin: "0px 8px"}}>Yearly Plan</p>*/}
                    {/*    </div>*/}
                    {/*</Label>*/}
                    <LineChart
                        dataSet={getTransformedDataForSubsCount(orgSubscriptionCount)}
                        colors={["#FECB17", "#D0004B", "#80CBC4"]}
                        categories={[
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                        ]}
                    />
                </FinancialBoxShadow>
            </Box>
            <Box width="100%" maxWidth="100%">
                <FinancialBoxShadow>
                    <Label fontSize="24px" fontWeight="700" justifyContent="center">
                        Total Revenue
                    </Label>
                    <label>
                        <div style={{flex: 'right'}}>
                            <BoxContainer>
                                <CheckBoxDateRange
                                    value={dateRange}
                                    handleSortBy={handleDateRange}
                                />
                            </BoxContainer>
                        </div>
                    </label>
                    <BarChart
                        xdim={780}
                        ydim={400}
                        margin={{top: 0, bottom: 60, left: 0, right: 0}}
                        name={"Amount"}
                        inputSeries={getData()}
                        categories={getCategories()}
                    />
                    {/*<CurveLineChart*/}
                    {/*    dataSet={transformedResult}*/}
                    {/*    colors={["#80CBC4", "#D0004B"]}*/}
                    {/*    categories={[*/}
                    {/*        "Jan",*/}
                    {/*        "Feb",*/}
                    {/*        "Mar",*/}
                    {/*        "Apr",*/}
                    {/*        "May",*/}
                    {/*        "Jun",*/}
                    {/*        "Jul",*/}
                    {/*        "Aug",*/}
                    {/*        "Sep",*/}
                    {/*        "Oct",*/}
                    {/*        "Nov",*/}
                    {/*        "Dec",*/}
                    {/*    ]}*/}
                    {/*/>*/}
                </FinancialBoxShadow>
            </Box>
        </Box>
    );
};

export default AdminFinancials;
