import React from "react";
import Icon from "../../../../../components/Icon/Icon";
import Box from "../../../../../components/Box";

const NotSavedPage = ({ setShow, handleEdit }) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div></div>
        <div onClick={setShow}>
          <Icon iconName="blackCross" />
        </div>
      </div>
      <div className="congrats-screen">
        <Box display="flex" justifyContent="center">
          <Icon iconName="notSaved" />
        </Box>
        <h4>Changes Not Saved</h4>
        <p>
          Your have unsaved changes that will be lost if you decide to continue.
        </p>

        <div className="d-flex justify-content-around mt-3">
          <button className="prev-bt" onClick={setShow}>
            Don’t Save
          </button>
          <button className="fill-bt" onClick={handleEdit}>
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default NotSavedPage;
