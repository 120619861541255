import React from "react";

function Banner() {
  return (
    <div className="login_banner">
      
      <div className="container">
        <div className="logo_im">LOGO</div>
        <div className="login_content">
          <h3>Pay Now</h3>
          <p>
            Antiren sän nir stenorat lyssna in. Sär gengen. <br />
            Kävagisk nepäskade label supraktig gigartad.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
