import React  from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingDetails } from "../../../customerAction";
import Dropdown from "../../../../../components/Dropdown";
import { countryCodeList } from "../../../../../constants/countryConstants";

const SelectBoxCountry = () => {
  const dispatch = useDispatch();

  const onboardingDetails = useSelector((state) => state.customerReducer.onboardingDetails)

  const handleChange = (value) => {
    dispatch(setOnboardingDetails("entity_country", value));
    const filteredData = countryCodeList.filter((item) => item.name === value)?.[0];
    if (filteredData?.code) {
      dispatch(setOnboardingDetails("country_code", filteredData?.code));
    }
  };

  const getCountryDropdownOptions = () => {
    return countryCodeList.map((item) => {
      return { label: item.name, value: item.name };
    });
  };

  return (
    <>
      <Dropdown
          allowSearch
          variant="outlinedGreen"
          defaultText="Select type"
          color="#1A1A1A"
          fontWeight="500"
          width="100%"
          height="54px"
          Icon="none"
          selectedValue={onboardingDetails?.entity_country}
          onOptionClick={(item) => handleChange(item?.value)}
          options={getCountryDropdownOptions()}
      />
    </>
  );
};

export default SelectBoxCountry;
