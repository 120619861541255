import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOnboardingDetails,
  setOnboardingMemberDetails,
} from "../../../customerAction";
import { Select } from "antd";
import { getPaymentMethodOption } from "../../../../../util/Utility";
import { fetchPlanList } from "../../../../Mosque/mosqueAction";
import Dropdown from "../../../../../components/Dropdown";

const options = getPaymentMethodOption();

function MosqueStepTwo() {
  const dispatch = useDispatch();

  const memberDetails = useSelector(
    (state) => state.customerReducer.memberDetails
  );
  const mosqueList = useSelector((state) => state.customerReducer.mosqueList);

  const handleChangeUser = (keyName, keyValue) => {
    dispatch(setOnboardingMemberDetails(keyName, keyValue));
    if (keyName === "entity_id") {
      dispatch(fetchPlanList(keyValue));
    }
  };

  const getMosqueOptions = () => {
    if (mosqueList) {
      return mosqueList.map((item) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
    }
    return [];
  };

  const renderEntityName = (val) => {
    const filteredData = getMosqueOptions().filter(
      (item) => item.value === val
    )?.[0];
    return filteredData?.label;
  };

  return (
    <div>
      <div className="mosque-form">
        <h4>Enter Admin Details</h4>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <label>
              Select Mosque<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Dropdown
              selectedValue={renderEntityName(memberDetails?.entity_id)}
              defaultText="Select mosque"
              onOptionClick={(item) =>
                handleChangeUser("entity_id", item.value)
              }
              color="#1A1A1A"
              fontWeight="500"
              width="100%"
              height="54px"
              options={getMosqueOptions()}
            />
          </div>
          <div className="col-lg-6 mb-3">
            <label>
              Payment Method<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Dropdown
              size="middle"
              selectedValue={memberDetails?.payment_method}
              defaultText="Select method"
              onOptionClick={(item) =>
                handleChangeUser("payment_method", item.value)
              }
              color="#1A1A1A"
              fontWeight="500"
              width="100%"
              height="54px"
              options={options}
            />
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

export default MosqueStepTwo;
