import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons";
import { adminLogin } from "../../../adminAction";

const LoginTabContainer = styled.div`
  position: relative;
  top: -100px;
`;
const LoginModal = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 452px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 34px;
`;

const DetailsBody = styled.div`
  margin-top: 30px;
`;

const GeneralLink = styled(Link)`
  text-decoration: unset;
`;

const LoginTab = () => {
  const dispatch = useDispatch();

  const [userDetails, setUserDetails] = useState({});
  const [isNext, setIsNext] = useState(false);

  const stepValidation = () => {
    return !!userDetails?.loginId?.trim() && !!userDetails?.password?.trim();
  };

  useEffect(() => {
    if (userDetails) {
      setIsNext(stepValidation());
    }
  }, [userDetails]);

  const submitOtpHandle = () => {
    const body = {
      contact_email: userDetails?.loginId,
      password: userDetails?.password,
    };

    const trimmedDetails = {};
    // Trim each attribute and assign it to trimmedDetails object
    for (const key in body) {
      if (typeof body[key] === "string") {
        trimmedDetails[key] = body[key].trim();
      } else {
        trimmedDetails[key] = body[key];
      }
    }

    if (trimmedDetails) {
      dispatch(adminLogin(trimmedDetails));
    }
  };

  const handleChangeDetails = (keyName, keyValue) => {
    const details = { ...userDetails };
    details[keyName] = keyValue;
    setUserDetails(details);
  };

  return (
    <LoginTabContainer>
      <LoginModal>
        <Label variant="text2" fontWeight={700} justifyContent="center">
          Login via
        </Label>
        <DetailsBody>
          <Label variant="text5" fontWeight={500} mb="10px">
            Login ID
          </Label>
          <Input
            value={userDetails?.loginId}
            onChange={(e) => handleChangeDetails("loginId", e.target.value)}
            border="1px solid #EFF0F7"
            boxShadow="0px 2px 6px #13124212"
            borderRadius="8px"
            placeholder="Enter Login ID"
            className="input-box"
            type="text"
          />
          <Label variant="text5" fontWeight={500} mt="24px" mb="12px">
            Password
          </Label>
          <Input
            value={userDetails?.password}
            onChange={(e) => handleChangeDetails("password", e.target.value)}
            border="1px solid #EFF0F7"
            boxShadow="0px 2px 6px #13124212"
            borderRadius="8px"
            placeholder="Enter Password"
            className="input-box"
            type="text"
            isEyeButton
          />
        </DetailsBody>

        <GeneralLink to="/admin/forget">
          <Label
            variant="text8"
            fontWeight={500}
            color="#D0004B"
            justifyContent="flex-end"
            mt="12px"
          >
            Forget Password?
          </Label>
        </GeneralLink>

        <Box display="flex" justifyContent="center" mt="30px">
          <Button
            onClick={submitOtpHandle}
            width="40px"
            type={isNext ? "primary" : "disabled"}
          >
            Login Now
          </Button>
        </Box>
      </LoginModal>
    </LoginTabContainer>
  );
};

export default LoginTab;
