import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons";

const LoginTabContainer = styled.div`
  position: relative;
  top: -100px;
  margin: 20px;
`;
const LoginModal = styled.div`
  width: 442px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 34px;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

const DetailsBody = styled.div`
`;

const BodyContainer = () => {
  const [userDetails, setUserDetails] = useState({});
  const [isNext, setIsNext] = useState(false);
  const [isApplied, setIsApplied] = useState(false);

  const stepValidation = () => {
    return !!userDetails?.password && !!userDetails?.confirmPassword;
  }

  useEffect(() => {
    if (userDetails) {
      setIsNext(stepValidation());
    }
  }, [userDetails])

  const handleChange = () => {
    setIsApplied(!isApplied)
  };

  return (
    <LoginTabContainer>
      <LoginModal>
        <DetailsBody>
          <Box>
            <Label mb="12px" isMandatory variant="text5" fontWeight={500}>Registered Contact Number</Label>
            <Input
              value={userDetails?.password}
              onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
              border="1px solid #EFF0F7"
              boxShadow="0px 2px 6px #13124212"
              borderRadius="8px"
              placeholder=""
              className="input-box"
              maxWidth="442px"
            />
          </Box>
          <Box mt="35px">
            <Label mb="12px" isMandatory variant="text5" fontWeight={500}>Amount Due</Label>
            <Input
              value={userDetails?.confirmPassword}
              onChange={(e) => setUserDetails({ ...userDetails, confirmPassword: e.target.value })}
              border="1px solid #EFF0F7"
              boxShadow="0px 2px 6px #13124212"
              borderRadius="8px"
              placeholder=""
              className="input-box"
              width="300px"
            />
          </Box>
        </DetailsBody>
        <Box display="flex" justifyContent="center" mt="30px">
          <Button width="40px" minWidth="150px" type={isNext ? "primary" : "disabled"}>
            Proceed To Pay
          </Button>
        </Box>
      </LoginModal>
    </LoginTabContainer>
  );
}

export default BodyContainer;
