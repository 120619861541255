import React from "react";
import Icon from "../../../../../components/Icon";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";

const DisableMemberModal = ({ show, handleDelete, setshow, text}) => {

    const handleOnClick = () => {
        setshow(!show);
        handleDelete();
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <div>

                </div>
                <div onClick={() => setshow(null, false)}>
                    <Icon
                        // style={{zIndex: 2000}}
                        iconName="blackCross"
                    />
                </div>
            </div>
            <div className="congrats-screen">
                <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
                <Box mt="15px">
                    <h4>Disable Member</h4>
                </Box>
                <Label color="#7E7E7E">{text}</Label>

                <div className="d-flex justify-content-around mt-3">
                    <button className="prev-bt" onClick={() => setshow(!show)}>
                        Cancel
                    </button>
                    <button className="fill-bt" onClick={() => handleOnClick()}>
                        Yes
                    </button>
                </div>
            </div>
        </>

    );
};

export default DisableMemberModal;
